import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'

type PriceData = {
  zero_usdt: number
}

// Agrega las direcciones de los pares aquí
const USDT_ZERO_PAIR = '0x23f21a8B466a899d9F60bEc6b772d72598dAf444'
const ZERO_SMR_PAIR = '0x0e34Effa5cef4Dca80c679B115Dc48E0D53E6a6B'
const SMR_USDT_PAIR = '0xA80619B831BaeE8E7730b3b6363CBD11d8C7eDEa'

// ABI del contrato de Pancake LP
const lpAbi: AbiItem[] = [
  {
    constant: true,
    inputs: [],
    name: 'getReserves',
    outputs: [
      { name: '_reserve0', type: 'uint112' },
      { name: '_reserve1', type: 'uint112' },
      { name: '_blockTimestampLast', type: 'uint32' },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
]

const useGetPriceData = () => {
  const [data, setData] = useState<PriceData | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const web3 = new Web3(Web3.givenProvider || 'https://json-rpc.evm.shimmer.network')

        // Crea instancias de los contratos
        const usdtZeroPairContract = new web3.eth.Contract(lpAbi, USDT_ZERO_PAIR)
        const zeroSmrPairContract = new web3.eth.Contract(lpAbi, ZERO_SMR_PAIR)
        const smrUsdtPairContract = new web3.eth.Contract(lpAbi, SMR_USDT_PAIR)

        // Obtén las reservas de los pares
        const usdtZeroReserves = await usdtZeroPairContract.methods.getReserves().call()
        const zeroSmrReserves = await zeroSmrPairContract.methods.getReserves().call()
        const smrUsdtReserves = await smrUsdtPairContract.methods.getReserves().call()

        // Calcula el precio de ZERO en USDT
        const zeroPriceInUsdtDirect = parseFloat(usdtZeroReserves._reserve1) / parseFloat(usdtZeroReserves._reserve0)

        // Calcula el precio de ZERO en SMR y de SMR en USDT
        const zeroPriceInSmr = parseFloat(zeroSmrReserves._reserve0) / parseFloat(zeroSmrReserves._reserve1)
        const smrPriceInUsdt = parseFloat(smrUsdtReserves._reserve1) / parseFloat(smrUsdtReserves._reserve0)

        // Calcula el precio de ZERO en USDT utilizando los precios de ZERO en SMR y de SMR en USDT
        const zeroPriceInUsdtIndirect = zeroPriceInSmr * smrPriceInUsdt

        // Compara los precios directo e indirecto de ZERO en USDT y selecciona el que tenga más liquidez
        const zeroPriceInUsdt = zeroPriceInUsdtDirect > zeroPriceInUsdtIndirect
        ? zeroPriceInUsdtDirect
        : zeroPriceInUsdtIndirect

/*         console.log("Precio ZERO-USDT:", zeroPriceInUsdt);
        console.log("Precio ZERO-SMR:", zeroPriceInSmr);
        console.log("Precio SMR-USDT:", smrPriceInUsdt); */

        const bestPrice = zeroPriceInUsdt *1000000000000

        setData({ zero_usdt: bestPrice })
        } catch (error) {
        /* console.error('Unable to fetch price data:', error) */
        }
        }

        fetchData()

        // Establece un intervalo para actualizar el precio cada minuto (60000 ms)
        const intervalId = setInterval(fetchData, 10000)

        // Limpia el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId)
        }, [setData])

        return data
        }

        export default useGetPriceData

/* import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'

type PriceData = {
  zero_usdt: number
}

// Agrega las direcciones de los pares aquí
const USDT_ZERO_PAIR = '0x23f21a8B466a899d9F60bEc6b772d72598dAf444'

// Direcciones de los tokens
const USDT_ADDRESS = '0x5A472D4206d005dEF925a3775b3470f4848Ba7D0'
const ZERO_ADDRESS = '0x5c09Ef80efb7Aefd0a25289715e0a164c98Ac713'

// ABI del contrato de Pancake LP
const lpAbi: AbiItem[] = [
  {
    constant: true,
    inputs: [],
    name: 'getReserves',
    outputs: [
      { name: '_reserve0', type: 'uint112' },
      { name: '_reserve1', type: 'uint112' },
      { name: '_blockTimestampLast', type: 'uint32' },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'token0',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
]

const useGetPriceData = () => {
  const [data, setData] = useState<PriceData | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const web3 = new Web3(Web3.givenProvider || 'https://json-rpc.evm.shimmer.network')

        // Crea instancias de los contratos
        const usdtZeroPairContract = new web3.eth.Contract(lpAbi, USDT_ZERO_PAIR)

        // Obtén las reservas de los pares
        const usdtZeroReserves = await usdtZeroPairContract.methods.getReserves().call()

        // Obtén la dirección del token0
        const token0Address = await usdtZeroPairContract.methods.token0().call()

        // Determina cuál de las reservas corresponde a USDT y cuál corresponde a ZERO
        const usdtReserves = token0Address.toLowerCase() === USDT_ADDRESS.toLowerCase() ? usdtZeroReserves._reserve0 : usdtZeroReserves._reserve1;
        const zeroReserves = token0Address.toLowerCase() === ZERO_ADDRESS.toLowerCase() ? usdtZeroReserves._reserve0 : usdtZeroReserves._reserve1;

        // Calcula el precio de ZERO en USDT
        const zeroPriceInUsdt = parseFloat(usdtReserves) / parseFloat(zeroReserves) * 1000000000000;
      
        // Imprime el precio en consola
        console.log('zeroPriceInUsdt:', zeroPriceInUsdt)
        console.log('usdtZeroReserves:', usdtZeroReserves)
        console.log('token0Address:', token0Address)
        console.log('usdtReserves:', usdtReserves)
        console.log('zeroReserves:', zeroReserves)


        setData({ zero_usdt: zeroPriceInUsdt })
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()

    // Establece un intervalo para actualizar el precio cada minuto (60000 ms)
    const intervalId = setInterval(fetchData, 10000)

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId)
  }, [setData])

  return data
}

export default useGetPriceData


 */