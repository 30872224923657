import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom';
import Web3 from 'web3'
import ReactModal from 'react-modal';
import NFTMarketplaceAbi from './NFTMarketplaceAbi.json'
import ERC721Abi from './ERC721Abi.json'
import './NFTMarketplace.css'

interface IToken {
  contractAddress: string;
  tokenId: string;
  collectionAddress: string;
  price: number;
}

ReactModal.setAppElement('#root');

let web3
if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' })
  web3 = new Web3((window as any).ethereum)
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.')
  web3 = new Web3()
}

interface EthereumProvider {
  on: (event: string, callback: (accounts: string[]) => void) => void;
  removeListener: (event: string, callback: (accounts: string[]) => void) => void;
  // cualquier otro método o propiedad que necesites usar
}

const marketplaceAddresses = [
  '0x08CAc391b0428D6564F813fc0163A01355Cedd2A',
  '0x1620100f7bfD882447F16fe6F857eAC64757773e'
]

const collections = [
  { address: '0x91cce1E5e3c5da8CF63e2d2Ee3C14ec987f7950f', video: '/Genesis.jpg' },
  { address: '0x8F6A97A176A61bc7516023aCa67A4931B638369C', video: '/Standard.jpg' }
]

function NFTMarketplaceDApp() {
  const [account, setAccount] = useState('')
  const [myTokens, setMyTokens] = useState<{ contract: string; collection: string; tokenId: string; isForSale: boolean; price?: string }[]>([])
  const [tokenPrices, setTokenPrices] = useState<{ [tokenId: string]: string }>({})
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentToken, setCurrentToken] = useState<IToken | null>(null);

  const loadAccounts = useCallback(async () => {
    const accounts = await (window.ethereum as any).request({ method: 'eth_requestAccounts' })
    console.log('Accounts debited: ', accounts)
    setAccount(accounts[0])
  }, [])

  const loadMyTokens = useCallback(async (acct: string) => {
    try {
      setLoading(true);
      setMyTokens([]); // Limpia el estado de myTokens al principio
      const tokens: { contract: string; collection: string; tokenId: string; isForSale: boolean; price?: string }[] = []
  
      await Promise.all(
        marketplaceAddresses.map(async (address) => {
          console.log('Checking contract at address: ', address)
          const marketplaceContract = new web3.eth.Contract(NFTMarketplaceAbi, address)
          const collectionAddress = await marketplaceContract.methods.nftCollection().call()
          console.log('Collection address for this contract: ', collectionAddress)
          const erc721Contract = new web3.eth.Contract(ERC721Abi, collectionAddress)
  
          const ownedTokensPromise = (async () => {

            if (!acct) {
              return false;
            }
            const balance = await erc721Contract.methods.balanceOf(acct).call()
            console.log('Token balance for this account: ', balance)
  
            const promises: Promise<any>[] = []
            for (let i = 0; i < balance; i++) {
              promises.push(erc721Contract.methods.tokenOfOwnerByIndex(acct, i).call())
            }
  
            const allTokenIds = await Promise.all(promises)
            console.log('All token IDs for this account: ', allTokenIds)
  
            return allTokenIds.map((tokenId) => ({
              contract: address,
              collection: collectionAddress,
              tokenId,
              isForSale: false
            }))
          })()
  
          const tokensForSalePromise = (async () => {
            const allTokenIds = await marketplaceContract.methods.getTokensForSale().call()
            console.log('All tokens for sale: ', allTokenIds)
          
            const tokensForSale: { contract: string; collection: string; tokenId: string; isForSale: boolean; price: string; }[] = []

            for (let i = 0; i < allTokenIds.length; i++) {
              const tokenId = allTokenIds[i];
              const sale = await marketplaceContract.methods.sales(tokenId).call()
              const isOwner = sale.seller.toLowerCase() === acct.toLowerCase();
              if (isOwner) {
                tokensForSale.push({
                  contract: address,
                  collection: collectionAddress,
                  tokenId,
                  isForSale: true,
                  price: web3.utils.fromWei(sale.price, 'ether')
                })
              }
            }
          
            return tokensForSale;
          })()
          
  
          const [ownedTokens, tokensForSale] = await Promise.all([ownedTokensPromise, tokensForSalePromise])
  
          if(Array.isArray(ownedTokens)) {
            tokens.push(
                ...(ownedTokens as {
                contract: string
                collection: string
                tokenId: string
                isForSale: boolean
                price?: string
                }[]),
                ...(tokensForSale as {
                contract: string
                collection: string
                tokenId: string
                isForSale: boolean
                price?: string
                }[])
            )
        }
        
        })
      )
  
      setMyTokens(tokens)
      console.log('Final state of tokens: ', tokens)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error loading tokens: ', error)
    }
  }, [])
  
  const listForSale = async (contractAddress: string, tokenId: string, collectionAddress: string) => {
    if (!tokenPrices[tokenId]) {
      console.log('Please enter a price for the token before listing.')
      return
    }
    const price = parseFloat(tokenPrices[tokenId])
    if (Number.isNaN(price)) {
      console.log('Please enter a valid number for the price.')
      return
    }
    // Set current token and open the modal
    setCurrentToken({ contractAddress, tokenId, collectionAddress, price });
    setModalIsOpen(true);
  }

  const confirmSale = async () => {
    if (currentToken === null) {
      console.log('There are no NFT selected for sale.')
      return
    }
    try {
      setModalIsOpen(false);
      setLoading(true);

      const marketplaceContract = new web3.eth.Contract(NFTMarketplaceAbi, currentToken.contractAddress)
      const tokenContract = new web3.eth.Contract(ERC721Abi, currentToken.collectionAddress)
  
      const isAlreadyApproved = await tokenContract.methods.isApprovedForAll(account, currentToken.contractAddress).call();
      if (!isAlreadyApproved) {
        await tokenContract.methods.setApprovalForAll(currentToken.contractAddress, true).send({ from: account })
      }
      
      await marketplaceContract.methods
        .listForSale(currentToken.tokenId, web3.utils.toWei(currentToken.price.toString(), 'ether'))
        .send({ from: account })
  
      loadMyTokens(account)
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.error('Error listing NFT for sale: ', error)
    }
  }
  

  const cancelSale = async (contractAddress: string, tokenId: string) => {
    try {
      setLoading(true);
      const marketplaceContract = new web3.eth.Contract(NFTMarketplaceAbi, contractAddress)

      await marketplaceContract.methods.cancelSale(tokenId).send({ from: account })

      loadMyTokens(account)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error canceling sale: ', error)
    }
  }

  useEffect(() => {
    const handleAccountsChanged = (accounts: string[]) => {
      console.log('Cuenta cambió: ', accounts[0]);
      setAccount(accounts[0]);
      loadMyTokens(accounts[0]);
    };
  
    loadAccounts().then(() => {
      loadMyTokens(account);
    });
  
    // Comprobar si window.ethereum está definido
    if (window.ethereum) {
      (window.ethereum as EthereumProvider).on('accountsChanged', handleAccountsChanged);
    }
  
    // Limpiando el evento cuando el componente se desmonta
    return () => {
      if (window.ethereum) {
        (window.ethereum as EthereumProvider).removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, [loadAccounts, loadMyTokens, account]);
  
  

  return (
    <div className="container-principal">
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Confirm NFT Sale"
        className="react-modal-content"
        style={{
        overlay: {
            backgroundColor: 'rgba(28, 28, 28, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            inset: 'auto',
        },
        }}
      >
        <div className='modal-content'>
        <div className="content">
            <img src="/warning.png" className='warning-icon' alt="" />
            <p className="center-text h2">Confirm NFT Sale</p>
        </div>
        <p className='p'>After selling your NFT you will be charged 8% for maintaining the ZeroSwapNFT ecosystem.</p>
        <p className='p'>Are you sure you want to sell this NFT?</p>
        <button type='button' className='confirmBUtton' onClick={confirmSale}>Confirm</button>
        <button type='button' className='cancelBUtton' onClick={() => setModalIsOpen(false)}>Cancel</button>
        </div>

      </ReactModal>

      <h2><Link type='button' to="/buy_nfts" className='selectorNFT3'>Buy NFTs</Link> My NFTs</h2>
      {loading || account === '' ? (
        <div>
        <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
      </div>
      ) : (
      <>
        <div className="nft-container">
          {myTokens.map((token, index) => {
            const tokenVideo =
              collections.find((collection) => collection.address.toLowerCase() === token.collection.toLowerCase())
                ?.video || ''
            return (
              <div className="nft-card" key={`${token.tokenId}-${token.collection}`}>
                <div className="video-container">
                  <img src={tokenVideo} alt="Genesis" className="claim-gif" loading="lazy" />
                  <div className="nft-id">NFT Id: {token.tokenId}</div>
                </div>
                {!token.isForSale ? (
                  <>
                    <input
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="Price in SMR"
                    value={token.isForSale ? token.price : tokenPrices[token.tokenId] || ''}
                    onChange={(e) => setTokenPrices((prices) => ({ ...prices, [token.tokenId]: e.target.value }))}
                    disabled={token.isForSale} />
                    <button type="button" onClick={() => listForSale(token.contract, token.tokenId, token.collection)}>Sell NFT</button>    
                  </>
                ) : (
                  <>
                    <input className='disabledinput' value={`${token.price} SMR`} disabled />
                    <button type="button" className="cancelSale" onClick={() => cancelSale(token.contract, token.tokenId)}>Cancel Sale</button>      
                  </>
                )}
              </div>
            )
          })}
        </div>
      </>
      )}
    </div>
  )
}

export default NFTMarketplaceDApp