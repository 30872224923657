import React, { useState, useEffect, useCallback } from 'react'; // Importa 'useCallback'
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import QuestionHelper from 'components/QuestionHelper';
import useI18n from 'hooks/useI18n'
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import './WalletClaim.css'; // Importando el archivo CSS
import Decimal from 'decimal.js';
import ERC20_ABI from './erc20ABI.json';
import LP_ABI from './LP_ABI.json';

ReactModal.setAppElement('#root');

// Define la interfaz para los objetos de información del token.
interface TokenInfo {
  balance;
  iconUrl?: string;  // Hazlo opcional si no siempre se va a utilizar
  token0Address?: string;  // Hazlo opcional si no siempre se va a utilizar
  token1Address?: string;  // Hazlo opcional si no siempre se va a utilizar
  token0BalanceInEther;
  token1BalanceInEther;
  token0Symbol,
  token1Symbol,
}

function toFixedCustom(x, decimalPlaces) {
  return Number(x).toFixed(decimalPlaces);
}

const contratoSharingAddress = '0xd5Cbbc488Afe39ae9dA230033e6b15168e586b5e';

const WalletClaim: React.FC = () => {

    const totalNFTs = 300;
    
    const [web3, setWeb3] = useState<Web3 | null>(null);
    const [walletClaimContract, setWalletClaimContract] = useState<Contract | null>(null);
    const [nftContract, setNftContract] = useState<Contract | null>(null);
    const [userNFTs, setUserNFTs] = useState<number[]>([]);
    const [nftClaims, setNftClaims] = useState<Record<number, boolean>>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [lpTokens, setLpTokens] = useState<Record<string, TokenInfo>>({});
    const [loading, setLoading] = useState(false);
    const [hasNFTs, setHasNFTs] = useState(false);
    const [userAccount, setUserAccount] = useState<string | null>(null);
    const [hasNFTsFinished, setHasNFTsFinished] = useState(false);
    const [updateToken, setUpdateToken] = useState(0);  // Añade esta línea
    const TranslateString = useI18n()


    const openModal = (id) => {
        setCurrentId(id);
        setIsModalOpen(true);
    };

    const getOwnedNFTs = useCallback(async (owner: string, contract: Contract) => {
      console.log("Ejecutando función getOwnedNFTs...");
      const balance = await contract.methods.balanceOf(owner).call();
      const promises = Array.from({ length: balance }, (_, i) => contract.methods.tokenOfOwnerByIndex(owner, i).call());
      const ids = await Promise.all(promises);
      const nftIds = ids.map(id => Number(id));
      setUserNFTs(nftIds); // Actualiza userNFTs aquí
      setHasNFTs(nftIds.length > 0); // Actualiza hasNFTs aquí
      setHasNFTsFinished(true);
      return nftIds;
    }, []);
    

    // Conexión a la billetera y configuración inicial.
    const connectWallet = useCallback(async () => {
      try {
          console.log("Ejecutando función connectWallet...");

          const ethereum = window.ethereum as any;
          if (!ethereum) {
              throw new Error('Please install MetaMask or another web3 wallet');
          }
  
          await ethereum.request({ method: 'eth_requestAccounts' });
          const web3Instance = new Web3(ethereum);
          setWeb3(web3Instance);

          setLoading(true);  // Comienza la carga
  
          const accounts = await web3Instance.eth.getAccounts();
          setUserAccount(accounts[0]);
  
          const [sharingABI, nftContractABI] = await Promise.all([
              fetch('/sharingABI.json').then(res => res.json()),
              fetch('/nftABI.json').then(res => res.json())
          ]);
  
          const contract = new web3Instance.eth.Contract(sharingABI as AbiItem[], contratoSharingAddress);
          setWalletClaimContract(contract);
  
          const nftContractAddress = '0x5631B7960972e34e85DFAE7B372CeaBe61b0aA38';
          const nftInstance = new web3Instance.eth.Contract(nftContractABI as AbiItem[], nftContractAddress);
          setNftContract(nftInstance);
  
          const userNFTIds = await getOwnedNFTs(accounts[0], nftInstance);
          setUserNFTs(userNFTIds);

          if(userNFTIds.length > 0){
            setHasNFTs(true);
          } else {
            setHasNFTs(false);
          }

          setLoading(false);  // Termina la carga
      } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      // Manejo de error genérico en caso de que no sea una instancia de Error
      console.error('An error occurred');
    }
      }
  }, [getOwnedNFTs]);

  

  // Función para reclamar recompensas.
  const claimStatusCache: Record<number, boolean> = {};

  const claimReward = async (id: number) => {
    try {
      console.log("Ejecutando función claimReward...");
      setLoading(true);  // Comienza la carga
      // Verifica si el resultado ya está en caché
      if (!(id in claimStatusCache)) {
        claimStatusCache[id] = await walletClaimContract?.methods.isClaimed(id).call();
      }

      if (claimStatusCache[id]) {
        console.error(`This NFT has already been claimed.`);
        return;
      }

      await walletClaimContract?.methods.claim(id).send({ from: userAccount });
      /* alert(`Successfully claimed rewards for NFT ID: ${id}`); */
      setNftClaims((prevClaims) => ({ ...prevClaims, [id]: true }));  // Añade esta línea
      await getTokenBalances();
      setLoading(false);  // Termina la carga

    } catch (error) {
      console.error('Error:', error);
      if (typeof error === 'object' && error !== null && 'code' in error) {
        if (error.code === 4001) { // El usuario rechazó la transacción
          console.log('You have rejected the transaction.');
          setLoading(false);
        } else if (error.code === -32000) { // Fondos insuficientes
          console.log('You have insufficient funds for this transaction.');
          setLoading(false);
        } else {
          console.log('Failed to claim rewards. Check console for error details.');
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  };


  // Obtén los saldos de tokens para la cuenta del usuario.
  const getTokenBalances = useCallback(async () => {
    if (walletClaimContract && web3) {
      console.log("Ejecutando función getTokenBalances...");
      setLoading(true);  // Comienza la carga
      const { 0: lpAddresses, 1: totalLpTokens } = await walletClaimContract.methods.getTokenListWithBalances().call();
  
      const promises = lpAddresses.map(async (lpAddress, i) => {
        const lpContract = new web3.eth.Contract(LP_ABI as AbiItem[], lpAddress);
        const token0AddressPromise = lpContract.methods.token0().call();
        const token1AddressPromise = lpContract.methods.token1().call();
        const reservesPromise = lpContract.methods.getReserves().call();
        const totalSupplyPromise = lpContract.methods.totalSupply().call();
  
        const [
          token0Address,
          token1Address,
          reserves,
          totalSupply,
        ] = await Promise.all([
          token0AddressPromise,
          token1AddressPromise,
          reservesPromise,
          totalSupplyPromise,
        ]);
  
        let balanceInWei = Number(totalLpTokens[i]) / totalNFTs;
        balanceInWei = Math.floor(balanceInWei);
  
        const percentage = (balanceInWei / totalSupply) * 100;
        const porcentajeDelTotalSupply = percentage / 100;
        const token0Balance = reserves._reserve0 * porcentajeDelTotalSupply;
        const token1Balance = reserves._reserve1 * porcentajeDelTotalSupply;
  
        const ERC20_ABI_ITEM: AbiItem[] = ERC20_ABI as AbiItem[];
  
        const token0Contract = new web3.eth.Contract(ERC20_ABI_ITEM, token0Address);
        const token1Contract = new web3.eth.Contract(ERC20_ABI_ITEM, token1Address);
        const balanceContract = new web3.eth.Contract(ERC20_ABI_ITEM, lpAddress);
  
        const token0DecimalsPromise = token0Contract.methods.decimals().call();
        const token0SymbolPromise = token0Contract.methods.symbol().call();
        const token1DecimalsPromise = token1Contract.methods.decimals().call();
        const token1SymbolPromise = token1Contract.methods.symbol().call();
        const balanceDecimalsPromise = balanceContract.methods.decimals().call();
  
        const [
          token0Decimals,
          token0Symbol,
          token1Decimals,
          token1Symbol,
          balanceDecimals,
        ] = await Promise.all([
          token0DecimalsPromise,
          token0SymbolPromise,
          token1DecimalsPromise,
          token1SymbolPromise,
          balanceDecimalsPromise,
        ]);
  
        const token0BalanceInEther = new Decimal(token0Balance).div(10 ** token0Decimals);
        const token1BalanceInEther = new Decimal(token1Balance).div(10 ** token1Decimals);
        const balance = new Decimal(balanceInWei).div(10 ** balanceDecimals);
  
        return {
          lpAddress,
          tokenInfo: {
            token0Address,
            token1Address,
            token0BalanceInEther: toFixedCustom(token0BalanceInEther.toFixed(8), 8),
            token1BalanceInEther: toFixedCustom(token1BalanceInEther.toFixed(8), 8),
            balance: toFixedCustom(balance.toFixed(8), 8),
            token0Symbol,
            token1Symbol,
          },
        };
      });
  
      const results: { lpAddress: string; tokenInfo: TokenInfo }[] = await Promise.all(promises);

      const newLpTokens: Record<string, TokenInfo> = {};
      results.forEach(({ lpAddress, tokenInfo }) => {
        newLpTokens[lpAddress] = tokenInfo;
      });
  
      setLpTokens(newLpTokens);
      setLoading(false);  // Termina la carga
    }
  }, [walletClaimContract, web3]);
  

  useEffect(() => {
    const checkClaimStatus = async (id: number) => {
      try {
        console.log("Ejecutando useEffect para verificar el estado de reclamación...");
        const claimStatus = await walletClaimContract?.methods.isClaimed(id).call();
        setNftClaims((prevClaims) => ({ ...prevClaims, [id]: claimStatus }));
        return claimStatus;  // Devuelve el estado de reclamación
      } catch (error) {
        console.error('Error:', error);
        return false;
      }
    };
    
    userNFTs.forEach((id) => {
      checkClaimStatus(id);
    });
  }, [userNFTs, walletClaimContract]);
  

  useEffect(() => {
    console.log("Ejecutando useEffect para inicializar...");
    const initialise = async () => {
      await connectWallet();
    }
    initialise();
    
    const ethereum = window.ethereum as any;
    if (ethereum) {
      ethereum.on('accountsChanged', async function (accounts: string[]) {
        if (accounts[0]) { // Comprueba que accounts[0] está definido
          setUserAccount(accounts[0]);
          if(nftContract) {
            // Conexión a la billetera y configuración inicial.
            await connectWallet();
            // Obtén NFTs para la nueva cuenta
            await getOwnedNFTs(accounts[0], nftContract);
          }

          setUpdateToken(prev => prev + 1);  // Añade esta línea
        }
      });      
    }
    return () => {
      if (ethereum) {
        ethereum.removeAllListeners('accountsChanged');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectWallet, getOwnedNFTs, updateToken]);
  


    useEffect(() => {
      if (walletClaimContract && web3 && isModalOpen) {
          console.log("Ejecutando useEffect para obtener saldos de tokens...");
          getTokenBalances();
      }
    }, [walletClaimContract, web3, getTokenBalances, isModalOpen]);

  
    return (
        <div className="contenedorPrincipal">
          <h2>Fee Sharing
            <QuestionHelper
                text={TranslateString(
                    300,
                    'Fee Sharing is our module that allows the community to earn passive income by staking their NFTs. 30% shared between 300 Genesis and 20% shared between 1700 Standards, from the DEX exchange fees. It can be claimed every 15 days.'
                )}
              />
          </h2>
          <div className='buttonContainer3'>
                <span className='selectorNFT'>Genesis</span>
                <Link type='button' to="/standard_claim" className='selectorNFT3'>Standard</Link>
            </div>
          {loading || !hasNFTsFinished ? (
            <div>
            <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
          </div>
          ) : hasNFTs && userAccount ? (
          <>
            <div className='contenedorNFTs'>

              {userNFTs.map((id) => (
                <div key={id} className='token-container'>
                      <img src="Genesis.jpg" alt="Genesis" className="claim-gif" loading="lazy" />
                      <div className="input-section">
                        <div className="token-id">
                          <span id='idNFT'>NFT {id}</span>
                        </div>
                        <div className="claim-button-container">
                          <button
                            type="button"
                            id={`claim-${id}`}
                            onClick={() => openModal(id)}
                            disabled={nftClaims[id]}
                            style={{backgroundColor: nftClaims[id] ? 'gray' : '#0080e9'}}
                          >
                            Claim
                          </button>
                        </div>
                      </div>
                    </div>
              ))}
            <ReactModal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Claim NFT Genesis Modal"
              className="react-modal-content"
              style={{
                overlay: {
                  backgroundColor: 'rgba(28, 28, 28, 0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                content: {
                  position: 'relative',
                  top: 'auto',
                  left: 'auto',
                  right: 'auto',
                  bottom: 'auto',
                  inset: 'auto',
                },
              }}
            >
              <div className="modal-content">
                <p className="center-text h2 mb10">
                  Claim NFT Genesis {currentId !== null ? currentId : ""}:
                </p>
                <div className='LPs'>
                  {Object.entries(lpTokens).map(([address, { token0Address, token1Address, balance, token0BalanceInEther, token1BalanceInEther, token0Symbol, token1Symbol }], index) => (
                      (token0BalanceInEther >= 0.00000001 && token1BalanceInEther >= 0.00000001) && (
                      <div key={address} className="center-content">
                          <div className='LPdiv'>
                              <img src={`/images/coins/${token0Address}.png`} alt={token0Address} className="token-icon token-icon0" />
                              <img src={`/images/coins/${token1Address}.png`} alt={token1Address} className="token-icon token-icon1" />
                              <p>{balance} LPs</p>
                          </div>
                              <>
                                  <img src={`/images/coins/${token0Address}.png`} alt={token0Address} className="token-icon token-icon00" />
                                  <p className='balances'>{token0BalanceInEther} {token0Symbol}</p>
                                  <img src={`/images/coins/${token1Address}.png`} alt={token1Address} className="token-icon token-icon01" />
                                  <p className='balances'>{token1BalanceInEther} {token1Symbol}</p>
                              </>
                      </div>
                      )
                  ))}

                  <div>
                    <button className="confirmBUtton" type='button' onClick={() => {
                        const id = currentId;
                        if(id !== null) {
                            claimReward(id);
                            setIsModalOpen(false);
                        }
                    }}>Confirm</button>
                    <button className="cancelBUtton" type='button' onClick={() => setIsModalOpen(false)}>Cancel</button>
                  </div>

                </div>
              </div>
            </ReactModal>
            </div>
          </>
          ) : (
            <div className='contenedorNFTs'>
              <div className='card'>
                <video autoPlay loop muted className='card-gif'>
                  <source src='NFTgenesis.mp4' type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
                <div className='card-text'>To have access to Fee Sharing you need to have at least 1 NFT (Genesis or Standard) in staking and be connected to MetaMask.</div>
                <Link to="/genesis_staking">
                  <button type='button' className='card-button'>STAKE NFT</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      );
};

export default WalletClaim;
