import React, { useEffect, useState, useCallback } from 'react'
import Web3 from 'web3'
import Tooltip from 'react-tooltip-lite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import LotteryAbi from './LotteryAbi.json' // Asegúrate de tener el archivo ABI en tu proyecto

import './styles.css'

// Configura las direcciones del contrato aquí
const LotteryContractAddress = '0xA7b5a0Bb9A56A759e9ed2Dc115A07BC842eb8100'

let web3 // Declaración de la variable
if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' })
  web3 = new Web3((window as any).ethereum)
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.')
  web3 = new Web3() // Esto creará una instancia de Web3 sin un proveedor específico.
}

const LotteryContract = new web3.eth.Contract(LotteryAbi, LotteryContractAddress)

const LotteryDApp = () => {
  const [account, setAccount] = useState<null | string>(null)
  const [lotteryPrice, setLotteryPrice] = useState(0)
  const [totalTokens, setTotalTokens] = useState(0)
  const [loading, setLoading] = useState(false)
  const [prizeNumber, setPrizeNumber] = useState(0)
  const [randomNumber, setRandomNumber] = useState(0)  
  const [failedAttempts, setFailedAttempts] = useState(0)


  const loadBlockchainData = useCallback(async () => {
    if (account) {
      try {
        setLoading(true)
        const price = await LotteryContract.methods.lotteryPrice().call({ from: account })
        const total = await web3.eth.getBalance(LotteryContractAddress);
        setLotteryPrice(web3.utils.fromWei(price, 'ether'))
        setTotalTokens(web3.utils.fromWei(total, 'ether'))
        setLoading(false)
      } catch (error) {
        console.error('Error loading blockchain data: ', error)
        setLoading(false)
      }
    }
  }, [account])

  const loadFailedAttempts = useCallback(async () => {
    try {
      const attempts = await LotteryContract.methods.failedAttempts().call({ from: account })
      setFailedAttempts(attempts)
    } catch (error) {
      console.error('Error loading failed attempts: ', error)
    }
  }, [account])

  const loadPrizeNumber = useCallback(async () => {
    try {
      const prizeNum = await LotteryContract.methods.getPrizeNumber().call({ from: account })
      setPrizeNumber(prizeNum)
    } catch (error) {
      console.error('Error loading prize number: ', error)
    }
  }, [account])

  useEffect(() => {
    loadBlockchainData()
    loadPrizeNumber()
    loadFailedAttempts()
  }, [loadBlockchainData, loadPrizeNumber, loadFailedAttempts])

  const playLottery = async () => {
    try {
      setLoading(true)
      // Jugar a la lotería
      const receipt = await LotteryContract.methods.playLottery().send({ from: account, value: web3.utils.toWei((lotteryPrice).toString(), 'ether') })

      // Obtener el número aleatorio de los logs del evento
      const randomNumberEvent = receipt.events.RandomNumberEvent
      if (randomNumberEvent) {
        const {randomNumber: newRandomNumber} = randomNumberEvent.returnValues
        setRandomNumber(newRandomNumber)
        console.log("Número aleatorio:", newRandomNumber)
      }

      setLoading(false)
      // Recargar datos después de jugar
      loadBlockchainData()
      loadPrizeNumber()
      loadFailedAttempts() // cargar los intentos fallidos después de jugar
    } catch (error) {
      console.error('Error playing the lottery: ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (window.ethereum && typeof window.ethereum.on !== 'undefined') {
      window.web3 = new Web3(window.ethereum as any)
      ;(window.ethereum as any).request({ method: 'eth_requestAccounts' })

      // Añadir listener para cambio de cuenta en Metamask
      window.ethereum.on('accountsChanged', async function (accounts: string[]) {
        setAccount(accounts[0])
      })
    }

    // Cargar cuenta al iniciar el componente
    (async () => {
      const accounts = await (window.ethereum as any).request({ method: 'eth_accounts' })
      setAccount(accounts[0])
    })()
  }, [])

  return (
    <div className="containerPrincipalz">
      <h2>
        Zero Lottery
        <Tooltip
          content="For every 1 ZERO play, you have a one in a million chance of winning 50% of the jackpot. The displayed prize pool represents the amount winners receive, while the actual jackpot in the contract is double.

The lottery's random number, ranging from 1 to 1,000,000, is generated using the ISC contract of Shimmer upon each contract creation. If your play matches this number, you win!

After each win, the lottery resets, generating a new winning number and preserving half the jackpot for the next round."
          background="black"
          color="white"
          arrow={false}
        >
          <FontAwesomeIcon className="fontawesome-info" icon={faInfoCircle} />
        </Tooltip>
      </h2>
      {loading ? (
        <div>
        <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
      </div>
      ) : (
        <div className="card">
          <h3>
            Prize {totalTokens / 2} <img className="iconologozero2" src="images/coins/smr.png" alt="" />
          </h3>
          <button className="playbutton" type="button" onClick={playLottery}>
            Play Lottery
          </button>
          <div className="lotteryInfo">
            <div className="lottery-price">
              One try: {lotteryPrice} <img className="iconologozero" src="images/coins/smr.png" alt="" />
            </div>
            <p className="mynumber-price">
              My number {randomNumber}
            </p>
            <p className="prizenumber">Prize number: {prizeNumber}</p> {/* Muestra el número del premio aquí */}
            <p className="failedAttempts">Failed Attempts: {failedAttempts}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default LotteryDApp
