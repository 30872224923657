import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
/*   {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://pancakeswap.finance/',
  }, */
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  
  {
    label: ('NFTs'),
    icon: 'TeamBattleIcon',
    items: [
      {
        label: ('My NFTs'),
        href: '/my_nfts',
      },
      {
        label: ('NFT Launchpad'),
        href: '/buy_nfts',
      },
      {
        label: ('NFT Marketplace'),
        href: '/marketplace',
      }, 
      {
        label: ('NFT Staking'),
        href: '/genesis_staking',
      },
      {
        label: ('Fee Reward'),
        href: '/swapfeereward',
      },
      /*
      {
        label: ('Fee Sharing'),
        href: '/genesis_claim',
      } */
    ],
  },
 
 
  {
    label: ('Staking LP'),
    icon: 'FarmIcon',
    href: '/token_staking',
  }, 
  /*
  {
    label: ('Zero Lottery'),
    icon: 'TicketIcon',
    href: '/lottery',
  }, 
  */

  {
    label: ('Launchpad'),
    icon: 'IfoIcon',
    href: '/view_sales'
  }, 
  
  {
    label: ('More'),
    icon: 'MoreIcon',
    items: [
      
      {
        label: ('Medium'),
        href: 'https://zeroswapnft.medium.com/',
      },
      {
        label: ('Discord'),
        href: 'https://discord.gg/hpFAkjtVyT',
      },
      {
        label: ('Youtube'),
        href: 'https://www.youtube.com/@ZeroSwapNFT',
      },
      {
        label: ('Twitter'),
        href: 'https://twitter.com/ZeroSwapNFT_',
      }, 
      {
        label: ('Coingecko'),
        href: 'https://www.coingecko.com/en/coins/zeroswapnft',
      },
/*       {
        label: ('Faucet Test SMR'),
        href: 'https://evm-toolkit.evm.testnet.shimmer.network/',
      } */
    ],
  },
  /* {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://pancakeswap.finance/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://pancakeswap.finance/pools',
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: 'https://pancakeswap.finance/lottery',
    status: {
      text: 'WIN',
      color: 'warning',
    },
  },
  {
    label: 'Collectibles',
    icon: 'NftIcon',
    href: 'https://pancakeswap.finance/nft',
  },
  {
    label: 'Team Battle',
    icon: 'TeamBattleIcon',
    href: 'https://pancakeswap.finance/competition',
  },
  {
    label: 'Teams & Profile',
    icon: 'GroupsIcon',
    items: [
      {
        label: 'Leaderboard',
        href: 'https://pancakeswap.finance/teams',
      },
      {
        label: 'Task Center',
        href: 'https://pancakeswap.finance/profile/tasks',
      },
      {
        label: 'Your Profile',
        href: 'https://pancakeswap.finance/profile',
      },
    ],
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Overview',
        href: 'https://pancakeswap.info',
      },
      {
        label: 'Tokens',
        href: 'https://pancakeswap.info/tokens',
      },
      {
        label: 'Pairs',
        href: 'https://pancakeswap.info/pairs',
      },
      {
        label: 'Accounts',
        href: 'https://pancakeswap.info/accounts',
      },
    ],
  },
  {
    label: 'IFO',
    icon: 'IfoIcon',
    href: 'https://pancakeswap.finance/ifo',
  }, */
  /* {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Contact',
        href: 'https://docs.pancakeswap.finance/contact-us',
      },
      {
        label: 'Voting',
        href: 'https://voting.pancakeswap.finance',
      },
      {
        label: 'Github',
        href: 'https://github.com/pancakeswap',
      },
      {
        label: 'Docs',
        href: 'https://docs.pancakeswap.finance',
      },
      {
        label: 'Blog',
        href: 'https://pancakeswap.medium.com',
      },
      {
        label: 'Merch',
        href: 'https://pancakeswap.creator-spring.com/',
      },
    ],
  }, */
]

export default config
