import PANCAKESWAP_DEFAULT_TOKEN_LIST from '../constants/token/pancakeswap.json';

export default async function addTokenToMetaMask(currency) {
    const ethereum = (window as any).ethereum;
    if (ethereum && ethereum.request) {
      const tokenList = PANCAKESWAP_DEFAULT_TOKEN_LIST.tokens;
      const token = tokenList.find((t) => t.symbol === currency.symbol);
      
      console.log(token); // Agrega esto para ver qué hay en 'currency'
      if (token) {
        const absoluteLogoURI = new URL(token.logoURI, window.location.origin).href;
        const wasAdded = await ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: token.address,
              symbol: token.symbol,
              decimals: token.decimals,
              image: absoluteLogoURI,
            },
          },
        });
      } else {
        console.log(`No se encontró el contrato para el símbolo ${currency.symbol}.`);
      }
    } else {
      console.log('Ethereum object does not exist on window.');
    }
  }
