import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QuestionHelper from 'components/QuestionHelper';
import useI18n from 'hooks/useI18n'
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import ERC20TokenFactoryAbi from './ERC20TokenFactoryAbi.json';
import ERC20Abi from './ERC20Abi.json';
import './App.css';

let web3;
if ((window as any).ethereum) {
  (window as any).ethereum.request({ method: 'eth_requestAccounts' });
  web3 = new Web3((window as any).ethereum);
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.');
  web3 = new Web3();
}

const ERC20TokenFactoryContractAddress = '0x6Bd5878c7a0c836c2d864B822fD638eBAc3C5EcD'; // Address of the deployed contract
const ERC20TokenFactoryContract = new web3.eth.Contract(ERC20TokenFactoryAbi, ERC20TokenFactoryContractAddress);


const CreateTokenDApp = () => {
  const [account, setAccount] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [tokenSupply, setTokenSupply] = useState('');
  const [newTokenAddress, setNewTokenAddress] = useState(null);
  const [loading, setLoading] = useState(false); // State for showing loading animation
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const [feeTokenContract, setFeeTokenContract] = useState<Contract | null>(null);
  const [userFeeTokenBalance, setUserFeeTokenBalance] = useState('');
  const [feeTokenAmount, setFeeTokenAmount] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state
  const TranslateString = useI18n()

  useEffect(() => {
    const loadAccounts = async () => {
      const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
      setAccount(accounts[0]);
  
      const feeTokenAddress = await ERC20TokenFactoryContract.methods.feeToken().call();
      const feeTokenContract2 = new web3.eth.Contract(ERC20Abi, feeTokenAddress);
      setFeeTokenContract(feeTokenContract2);
  
      const feeTokenAmountInWei = await ERC20TokenFactoryContract.methods.feeTokenAmount().call();
      const feeTokenAmountInEther = web3.utils.fromWei(feeTokenAmountInWei, 'ether');
      setFeeTokenAmount(feeTokenAmountInEther);
  
      loadUserFeeTokenBalance(accounts[0], feeTokenContract2, feeTokenAmountInEther);
    };
  
    const loadUserFeeTokenBalance = async (accountAddress: string, feeTokenContract2: any, feeTokenAmountInEther: string) => {
      const balance = await feeTokenContract2.methods.balanceOf(accountAddress).call();
      const userBalance = web3.utils.fromWei(balance, 'ether');
      setUserFeeTokenBalance(userBalance);
      setIsButtonDisabled(Number(userBalance) < Number(feeTokenAmountInEther)); // Disable the button if the user's balance is less than the fee
    };
  
    loadAccounts();
  
    // Listen for account changes
    (window as any).ethereum.on('accountsChanged', (accounts: string[]) => {
      setAccount(accounts[0]);
      if(feeTokenContract) {
        loadUserFeeTokenBalance(accounts[0], feeTokenContract, feeTokenAmount);
      }
    });
  }, [feeTokenAmount, feeTokenContract]);

  const createToken = async () => {
    setErrorMessage('');

    // Check if any field is empty or if the tokenSupply is 0
    if (!tokenName || !tokenSymbol || !tokenSupply || Number(tokenSupply) === 0) {
        setErrorMessage('Please fill all the fields and ensure the token supply is greater than 0.');
        return;
    }

    if (!feeTokenContract) {
        setErrorMessage('Fee token contract is not loaded.');
        return;
    }

    if (Number(userFeeTokenBalance) < Number(feeTokenAmount)) {
      setErrorMessage(`You do not have enough fee tokens to create a new token. Your current balance is ${userFeeTokenBalance} tokens.`);
      setIsButtonDisabled(true); // Disable the button
      return;
    }

    try {
        setLoading(true); // Start showing loading animation

        const feeTokenAmount2 = await ERC20TokenFactoryContract.methods.feeTokenAmount().call();

        // Using the non-null assertion operator to tell TypeScript that feeTokenContract is not null
        await feeTokenContract!.methods.approve(ERC20TokenFactoryContractAddress, feeTokenAmount2).send({ from: account });

        // Check the user's balance of the fee token
        const userBalance = await feeTokenContract!.methods.balanceOf(account).call();
        setUserFeeTokenBalance(web3.utils.fromWei(userBalance, 'ether'));

        // Make sure the user has enough tokens
        if (Number(userBalance) < Number(feeTokenAmount2)) {
          setErrorMessage('You do not have enough fee tokens to create a new token.');
          return;
        }

        const tokenSupplyInWei = web3.utils.toWei(tokenSupply, 'ether');

        const gas = await ERC20TokenFactoryContract.methods.createToken(tokenName, tokenSymbol, tokenSupplyInWei).estimateGas({ from: account });
        const response = await ERC20TokenFactoryContract.methods.createToken(tokenName, tokenSymbol, tokenSupplyInWei).send({ from: account, gas });
        setNewTokenAddress(response.events.TokenCreated.returnValues.tokenAddress);
    } catch (error) {
        console.error('Error creating token: ', error);
    } finally {
        setLoading(false); // Stop showing loading animation
    }
  };




  const addTokenToMetamask = async () => {
    try {
      const wasAdded = await (window as any).ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: newTokenAddress,
            symbol: tokenSymbol,
            decimals: 18,
            image: 'https://example.com/images/token-image.png', // this can be replaced with your token's logo URL
          },
        },
      });

      if (wasAdded) {
        console.log('Token was successfully added to MetaMask!');
      } else {
        console.log('There was an issue adding the token to MetaMask.');
      }
    } catch (error) {
      console.error('Error adding token to MetaMask: ', error);
    }
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
  };

  return (
    <div className="createToken">
      <h2>Token Creator
        <QuestionHelper
          text={TranslateString(
            300,
            'Token Creator is our module that makes it easy for the community to create ERC20 tokens in layer 2 Shimmer EVM.'
          )}
        />
      </h2>
      <p className='backtosale mb20'><a href="/#/create_sale/">Back to Create sale</a></p>
      
      {loading ? (
        // If loading is true, only show the loading GIF
        <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
        ) : (
        // If loading is false, show the form and possibly the new token information
        <>
          <input type="text" placeholder="Token Name" value={tokenName} onChange={(e) => setTokenName(e.target.value)} />
          <input type="text" placeholder="Token Symbol" value={tokenSymbol} onChange={(e) => setTokenSymbol(e.target.value)} />
          <input 
            type="number"
            placeholder="Token Supply" 
            value={tokenSupply} 
            onInput={handleInput}
            onChange={(e) => {
              // Keep the value as a string and verify it's in the desired range
              const value = e.target.value;
              if (value === '' || (Number(value) >= 0 && Number(value) <= 1000000000000000)) {
                setTokenSupply(value);
              }
            }} 
          />
          <p>
            <span>Fee: {feeTokenAmount}</span>
            <img className='reward-icon2' src="/favicon2.png" alt="zero token" />
          </p>
          
          <button type="button" className='createTokenButton' onClick={createToken}>Create Token</button>
          {errorMessage !== '' && (
                <div className="error">
                  {errorMessage}
                </div>
          )}
          {newTokenAddress && (
            <div className='createTokenInfo'>
              <p className='message'>Your new token address:</p>
              <p className='styloaddress'>{newTokenAddress}</p>
              <p>
                <button 
                  type='button' 
                  className='addMetaMask'
                  onClick={addTokenToMetamask}
                >
                  <img className='metamask' src="images/metamask2.png" alt="" />
                </button>
                <CopyToClipboard text={newTokenAddress}>
                  <button 
                    type='button' 
                    className='addMetaMask'
                  >
                    <img className='copy' src="images/copy.png" alt="" />
                  </button>
                </CopyToClipboard>
              </p>
              <p>
                <button 
                  type='button' 
                  className='createSaleButton'
                  onClick={() => history.push(`/create_sale/${newTokenAddress}`)}
                >
                  Sale Creator
                </button>
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CreateTokenDApp;