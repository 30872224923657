import { useEffect } from 'react'
import useGetPriceData from './useGetPriceData'
import { ZERO } from '../constants'

const useGetDocumentTitlePrice = () => {
  const priceData = useGetPriceData()

/*   const cakePriceUsd = priceData ? parseFloat(priceData.data[ZERO.address]?.price ?? 0) : 0
 */  /* const cakePriceUsd = priceData && priceData.data ? parseFloat(priceData.data[ZERO.address]?.price ?? 0) : 0 */
 const cakePriceUsd = priceData ? priceData.zero_usdt : 0




  const cakePriceUsdString =
    Number.isNaN(cakePriceUsd) || cakePriceUsd === 0
      ? ''
      : ` - $${cakePriceUsd.toLocaleString(undefined, {
          minimumFractionDigits: 6,
          maximumFractionDigits: 6,
        })}`

  useEffect(() => {
    document.title = `ZeroSwapNFT${cakePriceUsdString}`
  }, [cakePriceUsdString])
}
export default useGetDocumentTitlePrice
