import React from 'react';
import { useHistory } from 'react-router-dom';

const Marketplaces = () => {
  const history = useHistory();

  const handleMarketplaceClick = (route: string) => {
    history.push(route);
  };

  return (
    <div className='container-principal22'>
        <h2>Marketplace</h2>
        <div className="marketplaces">
        <div 
            className="marketplace-box"
            onClick={() => handleMarketplaceClick('/marketplaceGenesis')}
            onKeyDown={() => handleMarketplaceClick('/marketplaceGenesis')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTGenesisBanner.png" alt="Banner de Genesis" /> 
        </div>

        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/marketplaceTanglebulls')}
            onKeyDown={() => handleMarketplaceClick('/marketplaceTanglebulls')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTtanglebulls.png" alt="Banner de TangleBulls" /> 
        </div>
        
        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/marketplaceAirdrop')}
            onKeyDown={() => handleMarketplaceClick('/marketplaceAirdrop')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTAirdropBanner.png" alt="Banner de Airdrop" /> 
        </div>


        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/marketplaceOneofusArt')}
            onKeyDown={() => handleMarketplaceClick('/marketplaceOneofusArt')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTOneofusBanner2art.png" alt="Banner de Oneofus Art" /> 
        </div>
        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/marketplaceOrigen')}
            onKeyDown={() => handleMarketplaceClick('/marketplaceOrigen')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTOrigenBanner.png" alt="Banner de Origen" /> 
        </div>
        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/marketplaceOneofus')}
            onKeyDown={() => handleMarketplaceClick('/marketplaceOneofus')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTOneofusBanner2.png" alt="Banner de Oneofus" /> 
        </div>
        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/marketplaceTintan')}
            onKeyDown={() => handleMarketplaceClick('/marketplaceTintan')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTTintanBanner2.png" alt="Banner de TinTan" /> 
        </div>
        </div>
    </div>
  );
};

export default Marketplaces;
