import PANCAKESWAP_DEFAULT_TOKEN_LIST from '../constants/token/pancakeswap.json';

export default async function copyContract(currency) {
  const tokenList = PANCAKESWAP_DEFAULT_TOKEN_LIST.tokens;
  const token = tokenList.find((t) => t.symbol === currency.symbol);

  if (token) {
    try {
      await navigator.clipboard.writeText(token.address);
      console.log('Contract address copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  } else {
    console.error('No contract address found for selected currency');
  }
}
