import React from 'react';
import { useHistory } from 'react-router-dom';

const NFTLaunchpad = () => {
  const history = useHistory();

  const handleMarketplaceClick = (route: string) => {
    history.push(route);
  };

  return (
    <div className='container-principal22'>
        <h2>NFT Launchpad</h2>
        <div className="marketplaces">
        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/buynftstanglebulls')}
            onKeyDown={() => handleMarketplaceClick('/buynftstanglebulls')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTtanglebulls.png" alt="Banner de Tangle Bulls" /> 
        </div>
        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/buynftsoneofusart')}
            onKeyDown={() => handleMarketplaceClick('/buynftsoneofusart')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTOneofusBanner2art.png" alt="Banner de Oneofus ART" /> 
        </div>
        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('/buynftsoneofus')}
            onKeyDown={() => handleMarketplaceClick('/buynftsoneofus')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTOneofusBanner2.png" alt="Banner de Oneofus" /> 
        </div>
        <div 
            className="marketplace-box" 
            onClick={() => handleMarketplaceClick('buynftstintan')}
            onKeyDown={() => handleMarketplaceClick('buynftstintan')}
            role="button"
            tabIndex={0}
        >
            <img src="/NFTTintanBanner2.png" alt="Banner de Tin Tan" /> 
        </div>
        </div>
    </div>
  );
};

export default NFTLaunchpad;
