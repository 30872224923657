import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import Web3 from 'web3'
import NFTGenesisABI from './NFTGenesisABI.json'
import TokenABI from './ERC20Abi.json';
import './BuyNFTs.css' // Import the CSS file

// Direcciones
const NFTSale = '0x5bB65440933860053F6190580BB45800124af217'

// Web3
let web3
if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' })
  web3 = new Web3((window as any).ethereum)
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.')
  web3 = new Web3()
}

// Contratos
const NFTGenesisContract1 = new web3.eth.Contract(NFTGenesisABI, NFTSale)

const BuyNFT = () => {
  const [account, setAccount] = useState('')
  const [price1, setPrice1] = useState<number>(0)
  const [remaining1, setRemaining1] = useState<number>(0)
  const [loading, setLoading] = useState(true) // Agregamos el estado de carga.
  const [isEnoughBalance1, setIsEnoughBalance1] = useState<boolean>(false)
  const [balanceInWei, setBalanceInWei] = useState('0')
  const [maxSupply1, setMaxSupply1] = useState<number>(0) // Estado para MAX_SUPPLY.
  const [countdown, setCountdown] = useState<number>(0);
  const [countdownString, setCountdownString] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const targetTimestamp = 1698865200;  // Marca de tiempo objetivo en segundos
/*   const targetTimestamp = 1; */


  useEffect(() => {
    async function getAccountAndPrices() {
      setLoading(true)
      const accounts = await (window.ethereum as any).request({ method: 'eth_accounts' })
      setAccount(accounts[0])
      const fetchedMaxSupply1 = await NFTGenesisContract1.methods.MAX_SUPPLY().call()
      setMaxSupply1(fetchedMaxSupply1)
      
      // Aquí obtengo la dirección del token ERC20 de tu contrato NFT.
      const tokendepagoAddress = await NFTGenesisContract1.methods.getTokendepagoAddress().call();
      
      // Creamos una nueva instancia del contrato ERC20.
      const tokenContract = new web3.eth.Contract(TokenABI, tokendepagoAddress);
      
      // Obtenemos el balance del usuario en el token ERC20.
      const tokenBalance = await tokenContract.methods.balanceOf(accounts[0]).call();
      setBalanceInWei(tokenBalance)  // Asumiendo que el balance viene en Wei. 
      
      await getPrices()
      const totalSupply1 = await NFTGenesisContract1.methods.totalSupply().call()
      setRemaining1(maxSupply1 - totalSupply1)
      setLoading(false)
    }
    
  
    async function getPrices() {
      const fetchedPrice1Wei = new BigNumber(await NFTGenesisContract1.methods.NFTPrice().call())
      const ethPrice1 = web3.utils.fromWei(fetchedPrice1Wei.toFixed(), 'ether')
      setPrice1(ethPrice1)
      // Check if balance is enough
      console.log("Balance en Wei:", balanceInWei);
      console.log("Precio requerido en Wei:", fetchedPrice1Wei);

      const bigNumberBalance = new BigNumber(balanceInWei);
      const bigNumberFetchedPrice = new BigNumber(fetchedPrice1Wei);

      console.log("Balance como BigNumber:", bigNumberBalance.toString());
      console.log("Precio requerido como BigNumber:", bigNumberFetchedPrice.toString());

      const isEnough = bigNumberBalance.gte(bigNumberFetchedPrice);

      console.log("¿Es suficiente el balance?", isEnough);

      setIsEnoughBalance1(isEnough);
    }
    getAccountAndPrices()
    if (window.ethereum) {
      (window.ethereum as any).on('accountsChanged', function (accounts: any[]) {
        window.location.reload();
      })
    }
  }, [balanceInWei, maxSupply1])

  const buyNFT1 = async () => {
    try {
      setLoading(true);
  
      const fetchedTokendepagoAddress = await NFTGenesisContract1.methods.getTokendepagoAddress().call();
      const tokenInstance = new web3.eth.Contract(TokenABI, fetchedTokendepagoAddress);
      const requiredTokens = web3.utils.toWei('250', 'ether');
      
      // Comprueba el monto de tokens aprobados actualmente
      const allowance = await tokenInstance.methods.allowance(account, NFTSale).call();
  
      // Si el monto aprobado es menor que el necesario, entonces aprueba más tokens
      if (new web3.utils.BN(allowance).lt(new web3.utils.BN(requiredTokens))) {
        await tokenInstance.methods.approve(NFTSale, requiredTokens).send({ from: account });
      }
  
      // Realiza la compra del NFT
      await NFTGenesisContract1.methods.buyNFT().send({ from: account });
  
      // Actualiza el totalSupply1 y el remaining1
      const totalSupply1 = await NFTGenesisContract1.methods.totalSupply().call();
      setRemaining1(maxSupply1 - totalSupply1);
  
      setLoading(false);
    } catch (error) {
      console.error("Error buying NFT1: ", error);
      setLoading(false);
    }
  }
  
  
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const remainingSeconds = targetTimestamp - now;
  
      setCountdown(remainingSeconds);
  
      const days = Math.floor(remainingSeconds / 86400);
      const hours = Math.floor(remainingSeconds % 86400 / 3600);
      const minutes = Math.floor(remainingSeconds % 3600 / 60);
      const seconds = remainingSeconds % 60;
  
      setCountdownString(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);
    }, 1000);
  
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [countdown]);
  
  
  return (
    <div className="main-container">
      <h2>Buy Tin-Tan NFT {/* <Link type='button' to="/my_nfts_only2" className='selectorNFT3'>My NFTs</Link> */}</h2>
      {loading || account === null ? (
        <div>
          <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
        </div>
      ) : (
        <>
      <div className="containerpro">
        <div className="card">
          <div className="video-button-container">
            <div className="video-container">
              <img src="/TinTan2.png" width="100%" height="100%" alt="GIF Descriptivo" />
            </div>
            {!buttonDisabled &&
              <button className={`button ${remaining1 <= 0 || !isEnoughBalance1 || buttonDisabled ? 'button-disabled' : ''}`} 
                      type="button" 
                      onClick={buyNFT1} 
                      disabled={remaining1 <= 0 || !isEnoughBalance1 || buttonDisabled}>
                Buy Tin-Tan
              </button>
            }
            {countdown > 0 && (
              <p style={{textAlign: "center"}}>Time remaining until sales start: {countdownString}</p>
            )}

            {!isEnoughBalance1 && <p className="no-funds sold-out">Insufficient $MXT</p>}
            {remaining1 <= 0 && <p className="sold-out">Sold Out</p>}
            <p>Price: {price1} $MXT</p>
            <p>Remaining NFTs: {remaining1}</p>
          </div>
        </div>
      </div>
        </>
    )}
    </div>
  )
}

export default BuyNFT
