import React, { useEffect, useState, useCallback, useRef } from 'react'
import ReactModal from 'react-modal'
import QuestionHelper from 'components/QuestionHelper';
import useI18n from 'hooks/useI18n'
import Web3 from 'web3'
import ReactSlider from 'react-slider';
import ZeroStakingAbi from './ZeroStakingAbi.json' // Importa el ABI de tu contrato
import ERC20Abi from './ERC20Abi.json' // Importa el ABI del token ERC20
import LP_ABI from './LP_ABI.json';
import './styles.css'


const ZeroStakingContractAddress = '0xe688Cbb5A6Adf20BA247f08b2b401Ff15b264DBa' // Reemplaza con la dirección de tu contrato

ReactModal.setAppElement('#root')
let web3

if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' })
  web3 = new Web3((window as any).ethereum)
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.')
  web3 = new Web3()
}

const ZeroStakingContract = new web3.eth.Contract(ZeroStakingAbi, ZeroStakingContractAddress)

type Staking = {
  token: string
  amount: number
  rewardTokenBalance: number
  multiplier: number
  endTime: number
}

const smrtoken = '0xaeFaA069f3753669f72A42748d1F6Fe3f582ffBA';

const tokenNames = {
  '0x1816F0a7411A8fCEAE83551e2E57B0eD6aE67Dfd': 'SMR-ZERO LP',
  '0x890b1Fee84d93747664027Ce8E35214c40FF00C4': 'SMR-SPHE LP'
/*   '0x992afE8E636C7f3680b16492792dba83B14E1966': 'SMR-MXT LP',
  '0x2600e9EE7108fFcE574B005813a2eB13563B4b06': 'SMR-USDT LP' */
  /*     "0x02Ed0eCFa003e58fE0E9944727a12444fe3B5288": "NEBUL", */
  // add more mappings here...
}

const rewardTokenNames = {
  '0x1816F0a7411A8fCEAE83551e2E57B0eD6aE67Dfd': 'ZERO',
  '0x890b1Fee84d93747664027Ce8E35214c40FF00C4': 'SPHE'
/*   '0x992afE8E636C7f3680b16492792dba83B14E1966': 'MXT',
  '0x2600e9EE7108fFcE574B005813a2eB13563B4b06': 'ZERO' */
  // Añadir más mapeos aquí...
};

const rewardToken = {
  '0x1816F0a7411A8fCEAE83551e2E57B0eD6aE67Dfd': '0x5c09Ef80efb7Aefd0a25289715e0a164c98Ac713',
  '0x890b1Fee84d93747664027Ce8E35214c40FF00C4': '0xc5759E47b0590146675C560163036C302Fa05bC3'
/*   '0x992afE8E636C7f3680b16492792dba83B14E1966': '0xE956eaCe85f629629D8a68945B498e90508F59F6',
  '0x2600e9EE7108fFcE574B005813a2eB13563B4b06': '0x312A14Bf92C13df26718f46d1Fa23e24c1267f8b'   */
  // Añadir más mapeos aquí...
};


const StakingDApp = () => {
  const [account, setAccount] = useState('')
  const [tokens, setTokens] = useState([])
  const [selectedToken, setSelectedToken] = useState('')
  const [stakingAmount, setStakingAmount] = useState('')
  const [maxStakingAmount, setMaxStakingAmount] = useState('')
  const [stakingPeriod, setStakingPeriod] = useState(1)
  const [loading, setLoading] = useState(false)
  const provider = useRef(null as any)
  const [stakings, setStakings] = useState<Staking[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [withdrawData, setWithdrawData] = useState<{ token: string; index: number } | null>(null)
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null)
  const [tokenMultipliers, setTokenMultipliers] = useState({
    1: 1,
    2: 1,
    4: 1,
    8: 1,
    16: 1
  })

  const TranslateString = useI18n()
  const [tokenBalance, setTokenBalance] = useState('')
  const [rewardAmount, setRewardAmount] = useState(0);
  const [rewardTokenReserve, setRewardTokenReserve] = useState(0);
  const [apy, setApy] = useState(0);

  const tokenIndex = {};

  const getAndIncrementTokenIndex = (token) => {
    if (tokenIndex[token] === undefined) {
      tokenIndex[token] = 0;
    } else {
      tokenIndex[token]++;
    }
    return tokenIndex[token];
  };


  const openWithdrawModal = (token: string, index: number) => {
    console.log(index);
    setWithdrawData({ token, index })
    const staking = stakings[index] // Obtén los detalles de staking
    const timeLeft = staking.endTime * 1000 - Date.now() // Calcula el tiempo restante en milisegundos
    setTimeRemaining(timeLeft > 0 ? timeLeft : null) // Si el tiempo restante es negativo, establece el estado a null
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const confirmWithdraw = async () => {
    if (withdrawData) {
      try {
        closeModal()
        setLoading(true)
        await ZeroStakingContract.methods.withdraw(withdrawData.token, withdrawData.index).send({ from: account })
        setLoading(false)
        // Reload data after successful withdraw
        loadBlockchainData()
      } catch (error) {
        console.error('Error withdrawing: ', error)
        setLoading(false)
      }
    }
  }

  const loadWeb3 = useCallback(async () => {
    if (window.ethereum) {
      provider.current = window.ethereum
      window.web3 = new Web3(provider.current)
      await provider.current.request({ method: 'eth_requestAccounts' })

      provider.current.on('accountsChanged', async function (accounts: string[]) {
        setAccount(accounts[0])
      })
    } else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }, [])

  const loadAccounts = useCallback(async () => {
    const accounts = await provider.current.request({ method: 'eth_accounts' })
    setAccount(accounts[0])
  }, [])

  useEffect(() => {
    loadWeb3()
    loadAccounts()
  }, [loadWeb3, loadAccounts])

  const getTokenBalance = useCallback(async () => {
    if (account && selectedToken) {
      const ERC20Contract = new web3.eth.Contract(ERC20Abi, selectedToken)
      const balance = await ERC20Contract.methods.balanceOf(account).call()
      let balanceInEther = web3.utils.fromWei(balance.toString(), 'ether')

      // Redondear a 2 decimales
      balanceInEther = Math.floor(balanceInEther * 100) / 100

      setTokenBalance(balanceInEther)
      return balanceInEther
    }
    return 0 // Retorna 0 si account o selectedToken no están definidos
  }, [account, selectedToken])

  const loadBlockchainData = useCallback(async () => {
    console.log('Cargando datos de la blockchain...')
    if (account && ZeroStakingContractAddress && ZeroStakingContract) {
      console.log('La cuenta es: ', account)
      try {
        setLoading(true)
        const stakingTokens = await ZeroStakingContract.methods.getStakingTokens().call({ from: account })
        console.log('stakingTokens: ', stakingTokens)
        setTokens(stakingTokens)
        if (selectedToken==='') {
          setSelectedToken(stakingTokens[0])
          console.log('selectedToken: ', stakingTokens[0])
        }

        const balanceInEther = await getTokenBalance()
        console.log('balanceInEther: ', balanceInEther)

        const adjustedBalance = balanceInEther > 1 ? Math.floor(balanceInEther - 1) : 0;
        setStakingAmount(adjustedBalance.toString());
        setMaxStakingAmount(adjustedBalance.toString());

        if (selectedToken) {
          try {
            const multipliers = await ZeroStakingContract.methods.tokenMultipliers(selectedToken).call({ from: account });
            console.log('multipliers: ', multipliers);
          
            if (!multipliers || multipliers.length === 0) {
              console.log("Multipliers están vacíos o indefinidos.");
              return;
            }
        
            setTokenMultipliers({
              1: multipliers[0],
              2: multipliers[1],
              4: multipliers[2],
              8: multipliers[3],
              16: multipliers[4]
            });
          } catch (error) {
            console.error("Error al obtener multiplicadores: ", error);
          }
        } else {
          console.log('selectedToken no está definido.');
        }        
        
        const allStakingsPromises: Promise<Staking[]>[] = stakingTokens.map(async (token) => {
          const stakes = await ZeroStakingContract.methods.getStakes(token, account).call({ from: account })
          console.log(`stakes for token ${token}: `, stakes)
          return stakes.map((stake) => ({ token, ...stake }))
        })

        const allStakingsArrays: Staking[][] = await Promise.all(allStakingsPromises)
        const allStakings: Staking[] = allStakingsArrays.flat()

        console.log('allStakings: ', allStakings)

        setStakings(allStakings)

        // Importando el ABI del LP token
        const LPContractABI = new web3.eth.Contract(LP_ABI, selectedToken);

        // Obteniendo las reservas del LP token
        const reserves = await LPContractABI.methods.getReserves().call();
        console.log("Reservas: ", reserves);

        // Obteniendo las direcciones de los tokens que componen el par LP
        const token0Address = await LPContractABI.methods.token0().call();
        const token1Address = await LPContractABI.methods.token1().call();

        // Determinando qué reserva corresponde al token de recompensa
        const rewardTokenAddress = rewardToken[selectedToken];


        console.log("Token0: ", token0Address);
        console.log("Token1: ", token1Address);

        if (token0Address.toLowerCase() === rewardTokenAddress.toLowerCase()) {
          setRewardTokenReserve(reserves._reserve0);
          console.log("RewardTokenReserve encontrado en _reserve0.");
        } else if (token1Address.toLowerCase() === rewardTokenAddress.toLowerCase()) {
          setRewardTokenReserve(reserves._reserve1);
          console.log("RewardTokenReserve encontrado en _reserve1.");
        } else if (token0Address.toLowerCase() === smrtoken.toLowerCase()) {
          setRewardTokenReserve(reserves._reserve0);
          console.log("RewardTokenReserve encontrado en _reserve0.");
        } else if (token1Address.toLowerCase() === smrtoken.toLowerCase()) {
          setRewardTokenReserve(reserves._reserve1);
          console.log("RewardTokenReserve encontrado en _reserve1.");
        }else {
          console.log("El token de recompensa no se encuentra en este par de LP.");
          console.log(reserves._reserve0)
          console.log(reserves._reserve1)

          setRewardTokenReserve(0);
        }

        setLoading(false)
      } catch (error) {
        console.error('Error loading blockchain data: ', error)
        setLoading(false)
      }
    } else {
      console.log('La cuenta no se ha definido aún.')
    }
  }, [account, selectedToken, getTokenBalance])


  useEffect(() => {
    loadBlockchainData()
  }, [loadBlockchainData])

  const stakeTokens = async () => {
    try {
      setLoading(true)

      // Create instance of ERC20Contract for selected token
      const ERC20Contract = new web3.eth.Contract(ERC20Abi, selectedToken)

      // Get the number of decimals for the token
      const decimals = await ERC20Contract.methods.decimals().call()
      const stakingAmountInWei = web3.utils.toBN(stakingAmount).mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals)))

      // Check the amount of tokens the staking contract is allowed to spend on behalf of the user
      const allowance = await ERC20Contract.methods.allowance(account, ZeroStakingContractAddress).call()

      // If the allowed amount is less than the amount the user wants to stake, then we approve the tokens
      if (new web3.utils.BN(allowance).lt(stakingAmountInWei)) {
        // Approve tokens
        const gas = await ERC20Contract.methods.approve(ZeroStakingContractAddress, stakingAmountInWei)
          .estimateGas({from: account});

        await ERC20Contract.methods.approve(ZeroStakingContractAddress, stakingAmountInWei)
          .send({ from: account, gas });
        console.log('Token approved successfully')
      }

      // Proceed to staking only after potential approval
      const stakeReceipt = await ZeroStakingContract.methods
        .stake(selectedToken, stakingAmountInWei, stakingPeriod)
        .send({ from: account })
      console.log('Stake completed successfully', stakeReceipt)

      setLoading(false)

      // Reload data after successful staking
      loadBlockchainData()
    } catch (error) {
      console.error('Error staking: ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getTokenBalance()
  }, [getTokenBalance])



  useEffect(() => {
    const calculateRewardAmount = async () => {
      if (!selectedToken || !tokenBalance || !rewardTokenReserve) {
        console.log("Faltan datos para calcular la cantidad de recompensa.");
        console.log(selectedToken)
        console.log(tokenBalance)
        console.log(rewardTokenReserve)
        return;
      }
  
      try {
        const LPContractABI = new web3.eth.Contract(LP_ABI, selectedToken);
        const totalLPTokens = await LPContractABI.methods.totalSupply().call();
  
        if (!totalLPTokens) {
          console.log("No se pudo obtener el suministro total de tokens LP.");
          return;
        }
  
        const multiplierMapping = {
          1: '1',
          2: '2',
          4: '4',
          8: '8',
          16: '16'
        };
  
        const multiplierKey = multiplierMapping[stakingPeriod];
        const multiplier = tokenMultipliers[multiplierKey] ? tokenMultipliers[multiplierKey] / 100 : 0;


        const decimalMultiplier = multiplier; // Ya en forma decimal, no necesitas dividir entre 100
        const APY = (((1 + decimalMultiplier) ** (12 / multiplierKey) - 1) * 100);
        setApy(APY);        
        

        
        console.log("tokenMultipliers:", tokenMultipliers);
        console.log("multiplierKey:", multiplierKey);
        console.log("multiplier:", multiplier);
        console.log("APY:", APY);
  
        const calculatedRewardAmount = ((Number(stakingAmount) / Number(totalLPTokens)) * Number(rewardTokenReserve)) * multiplier / 10;
        console.log("Reward Amount with Multiplier: ", calculatedRewardAmount);
        setRewardAmount(calculatedRewardAmount);
      } catch (error) {
        console.error("Ocurrió un error al calcular la cantidad de recompensa: ", error);
      }
    };
  
    calculateRewardAmount();
  }, [tokenBalance, rewardTokenReserve, selectedToken, tokenMultipliers, stakingPeriod, stakingAmount]); // No necesitamos incluir multiplierMapping aquí porque ahora está dentro del useEffect


  return (
    <div className="containerPrincipaly">
      <ReactModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel="Stake NFT Airdrop Modal"
        className="react-modal-content"
        style={{
          overlay: {
            backgroundColor: 'rgba(28, 28, 28, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            inset: 'auto'
          }
        }}
      >
        <div className="modal-content">
          <div className="content">
            <img src="/warning.png" className="warning-icon" alt="" />
            <p className="center-text h2">Withdraw Tokens Staked:</p>
          </div>

          <p className="p">Are you sure you want to Withdraw? </p>
          {timeRemaining && (
            <div className="staking-data">
              <p>
                <strong>Warning:</strong> You havent fulfilled the staking period,
              </p>
              <p>
                you still have {Math.floor(timeRemaining / (1000 * 60 * 60 * 24))} days,{' '}
                {Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))} hours,{' '}
                {Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))} minutes and{' '}
                {Math.floor((timeRemaining % (1000 * 60)) / 1000)} seconds to go.
              </p>
              <p>Withdrawing now will result in a 10% penalty.</p>
            </div>
          )}
          <button className="confirmBUtton" type="button" onClick={confirmWithdraw}>
            Confirm
          </button>
          <button className="cancelBUtton" type="button" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </ReactModal>

      {loading ? (
        <div>
          <img className="loader" src="/images/blue-loader3.svg" alt="Loading..." />
        </div>
      ) : (
        <>
        {tokens.length > 0 ? (
          <div className="staking-form">
            <h2>
              LP Staking
              <QuestionHelper
                text={TranslateString(
                  300,
                  'LP Staking is our module that allows the community to stake their LP tokens for a period of X months and earn a profit.'
                )}
              />
            </h2>
            <label className="w100" htmlFor="token-select">
              <select
                id="token-select"
                className="staking-form-input"
                value={selectedToken}
                onChange={(e) => setSelectedToken(e.target.value)}
              >
                {tokens.map((token, index) => (
                  <option key={token} value={token}>
                    {tokenNames[token] || token}
                  </option>
                ))}
              </select>
            </label>




            <label className="w100" htmlFor="amount-input">
            <ReactSlider
                id="amount-slider"
                min={0}
                max={parseFloat(maxStakingAmount)}
                value={parseFloat(stakingAmount)}
                onChange={(value) => setStakingAmount(value)}
                className="horizontal-slider"
              />
              <input
                id="amount-input"
                className="staking-form-input"
                type="number"
                value={stakingAmount}
                onChange={(e) => {
                  let inputValue = e.target.value;

                  // Validación para asegurarse de que el valor no exceda el valor máximo (stakingAmount)
                  if (parseFloat(inputValue) > parseFloat(maxStakingAmount)) {
                    inputValue = maxStakingAmount;
                  }

                  setStakingAmount(inputValue);
                }}
                placeholder="Amount to stake"
              />
            </label>


            <label className="w100" htmlFor="period-select">
              <select
                id="period-select"
                className="staking-form-input"
                value={stakingPeriod}
                onChange={(e) => setStakingPeriod(Number(e.target.value))}
              >
                <option value={1}>Stake 1 Month</option>
                <option value={2}>Stake 2 Months</option>
                <option value={4}>Stake 4 Months</option>
                <option value={8}>Stake 8 Months</option>
                <option value={16}>Stake 16 Months</option>
              </select>
            </label>
            <div className="reward-amount-display">
              Reward {rewardAmount.toFixed(2)} <img src={`/images/coins/${rewardToken[selectedToken]}.png`} alt='Reward Token Icon' /> ~
            </div>
{/*             <div className="reward-amount-display">
              APY {apy.toFixed(2)} % ~
            </div> */}


            <button
              className="staking-form-button"
              type="button"
              onClick={stakeTokens}
              disabled={!selectedToken || !stakingAmount || !stakingPeriod}
            >
              Staking Now
            </button>
          </div>
        ) : (
          <div className="staking-form">
            <h2>
              LP Staking
              <QuestionHelper
                text={TranslateString(
                  300,
                  'LP Staking is our module that allows the community to stake their LP tokens for a period of X months and earn a profit.'
                )}
              />
            </h2>
            <p>At the moment there is nothing to stake.</p>
          </div>
        )}

          <div className="stakedContainer">
            <div className="tokensStakedContainer">
              {stakings.map((staking, index) => {
                const localIndex = getAndIncrementTokenIndex(staking.token);
                const key = `${staking.endTime}-${index}`
                const amountInEther = web3.utils.fromWei(staking.amount.toString(), 'ether')
                const rewardTokenBalanceInEther = web3.utils.fromWei(staking.rewardTokenBalance.toString(), 'ether')

                // Get the token name from the mapping, or fallback to the contract address if not found
                const tokenName = tokenNames[staking.token] || staking.token
                const rewardTokenName = rewardTokenNames[staking.token] || staking.token

                // Define the image path
                const imagePath = `/images/coins/${staking.token}.png`
                // Define RewardToken Image
                const imagePath2 = `/images/coins/${rewardToken[staking.token]}.png`

                return (
                  <div className="token-staked" key={key}>
                    <div className="token-staked-content">
                      <div className="token-name">
                        {tokenName} <img src={imagePath} alt={tokenName} />
                      </div>
                      <div className="token-data">
                        <strong>Quantity:</strong> {amountInEther} {tokenName}
                      </div>
{/*                       <div className="token-data">
                        <strong>Multiplier:</strong> {staking.multiplier / 100}
                      </div> */}
                      <div className="token-data">
                        <strong>Reward:</strong> <span>{(Math.floor((rewardTokenBalanceInEther * staking.multiplier / 100) * 100) / 1000).toFixed(2)}  <img src={imagePath2} alt={rewardTokenName} /></span>
                      </div>


                      <div className="token-data">
                      <strong>Days Left for Withdraw:</strong> {Math.max(0, Math.ceil((new Date(staking.endTime * 1000).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)))}
                      </div>
                      <button
                        type="button"
                        className={`w100 token-data-button staking-form-button ${new Date(staking.endTime * 1000) > new Date() ? 'disabled' : ''}`}
                        onClick={() => {
                         /*  if (new Date(staking.endTime * 1000) <= new Date()) { */
                            openWithdrawModal(staking.token, localIndex);
                          /* } */
                        }}
                        /* disabled={new Date(staking.endTime * 1000) > new Date()} */
                      >
                        {new Date(staking.endTime * 1000) > new Date() ? 'Staking...' : 'Withdraw'}
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default StakingDApp
