import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import Web3 from 'web3'

type ReturnValues = {
  [key: string]: any
}

type Event = {
  transactionHash: string
  returnValues: ReturnValues
}

let web3 // Declaración de la variable
if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' })
  web3 = new Web3((window as any).ethereum)
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.')
  web3 = new Web3() // Esto creará una instancia de Web3 sin un proveedor específico.
}

const NFTTransactions = () => {
  const [events, setEvents] = useState<Event[]>([])

  const [account, setAccount] = useState<null | string>(null)

  const loadAccounts = useCallback(async () => {
    if ((window as any).ethereum) {
      (window as any).ethereum.on('accountsChanged', async (accounts: string[]) => {
        // Aquí es donde se establece el listener de cambio de cuentas
        setAccount(accounts[0])
      })
  
      const accounts = await (window as any).ethereum.request({ method: 'eth_accounts' })
      setAccount(accounts[0])
    }
  }, [])
  
  useEffect(() => {
    loadAccounts()
  }, [loadAccounts])

  useEffect(() => {
    if (!account) {
      setEvents([]); // Si no hay cuenta conectada, limpia la lista de eventos
      return;
    }
  
    fetch('Events/events.json')
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter((event) => 
          (event.returnValues.buyer && event.returnValues.buyer.toLowerCase() === account.toLowerCase()) ||
          (event.returnValues.seller && event.returnValues.seller.toLowerCase() === account.toLowerCase())
        )
        data = filteredData
  
        data.sort((a, b) => {
          if (a.blockNumber !== b.blockNumber) {
            return b.blockNumber - a.blockNumber // Aquí es donde se ha cambiado
          }
        
          if (a.event === 'TokenSold' && b.event === 'TokenPurchased') {
            return -1
          }
        
          if (a.event === 'TokenPurchased' && b.event === 'TokenSold') {
            return 1
          }
        
          return a.id.localeCompare(b.id)
        })        
  
        setEvents(data)
      })
      .catch((error) => {
        console.error('Error loading event data:', error)
      })
  }, [account])

  return (
    <div className="transactionsNFTContainer">
      <h2><Link type='button' to="/marketplace" className='selectorNFT5'>Marketplace</Link> - <Link type='button' to="/my_nfts" className='selectorNFT5'>My NFTs</Link> <span className='icontransaction'><img src="images/tx.png" alt="" /></span></h2>
      {events.map((event) => (
        <div className="transactionCard"> {/* Añadida la clase aquí */}
          {Object.keys(event.returnValues).map((key) =>
            Number.isNaN(Number(key)) ? (
              <div key={key}>
                {key === "buyer" ? <p>Purchase</p> : null}
                {key === "seller" ? <p>Sale</p> : null}
                {key === "tokenId" ? <p>NFT ID: {event.returnValues[key]}</p> : null}
                {key === "collection" ? 
                <p>{key.charAt(0).toUpperCase() + key.slice(1)}: {event.returnValues[key] === '0x8F6A97A176A61bc7516023aCa67A4931B638369C' ? 'Airdrop' : 
                event.returnValues[key] === '0x91cce1E5e3c5da8CF63e2d2Ee3C14ec987f7950f' ? 'Genesis' : 
                event.returnValues[key]}</p> : null}
                {key === "price" || key === "commission" ? 
                <p>{key.charAt(0).toUpperCase() + key.slice(1)}: {web3.utils.fromWei(event.returnValues[key], 'ether')} SMR</p> : null}
              </div>
            ) : null
          )}
        </div>
      ))}
    </div>
  )
}

export default NFTTransactions
