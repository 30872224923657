import React from 'react';
import { Link } from 'react-router-dom';
import './WalletClaim.css'; 

const ClaimReward = () => {
    return (
        <div className="container">
            <h2>Claim Reward</h2>
            <div className='buttonContainer'>
                <Link type='button' to="/genesis_claim" className='selectorNFT'>Genesis</Link>
                <Link type='button' to="/standard_claim" className='selectorNFT'>Standard</Link>
            </div>
        </div>
    );
};

export default ClaimReward;