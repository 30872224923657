import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Web3 from 'web3'
import BN from 'bn.js'
import ReactModal from 'react-modal';
import SalesAbi from './SalesAbi.json'
import ERC20Abi from './ERC20Abi.json'
import './sale.css'

ReactModal.setAppElement('#root');

let web3
if ((window as any).ethereum) {
  (window as any).ethereum.request({ method: 'eth_requestAccounts' })
  web3 = new Web3((window as any).ethereum)
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.')
  web3 = new Web3()
}

interface SaleDetails {
  address: string
  getTokenContract: string
  balanceOf: number
  getTokenBalance: string
  getCollectedETH: number
  getSoftcap: number
  getHardcap: number
  getStatus: string
  getStartTime: number
  getEndTime: number
  unlockDate: Date
  getExpectedLiquidityETH: string
  getContractETHBalance: number
  getSellerAddress: string
  getLiquidityETH: string
  getLiquidityTokenAmount: string
  getLimitPerAccountEnabled: boolean
  getLimitPerAccount: string
  getLiquidityPairAddress: string
  getLiquidityUnlockTime: number
  isWhitelisted: boolean
  isOwnerOfNFT: boolean
  isWhitelistActive: boolean
  getVestingEnabled: boolean
  getVestingInterval: number
  getVestingDuration: number
  getClaimableTokens: string
  getTokensPerEth: number
  getTokensPerEth2: number
  getTokensPerEthListing: number
  getLiquidityPercent: number
  getContributions: number
  getUnclaimedTokens: number
  tokenName: string
  tokenSymbol: string
}

function roundToSix(num) {    
  return +(Number(num).toFixed(6));
}


const SaleDetails = () => {
  const { saleAddress } = useParams<{ saleAddress: string }>()
  const [account, setAccount] = useState('')
  const [sale, setSale] = useState<SaleDetails | null>(null)
  const [loading, setLoading] = useState(false)
  const [copyMessage, setCopyMessage] = useState('')
  const [tokenAmount, setTokenAmount] = useState<number | null>(null)
  const [convertedSMR, setConvertedSMR] = useState(0)
  const [isSeller, setIsSeller] = useState(false)
  const [accountBalance, setAccountBalance] = useState<number>(0)
  const [maxTokenAmount, setMaxTokenAmount] = useState(0)
  const [maxTokenAmountReal, setMaxTokenAmountReal] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const loadSale = useCallback(async () => {
    if (account) {
      setLoading(true)
      const saleContract = new web3.eth.Contract(SalesAbi, saleAddress)
      const tokenAddress = await saleContract.methods.getTokenContract().call()
      const erc20Contract = new web3.eth.Contract(ERC20Abi, tokenAddress)
      const getEndTime = Number(await saleContract.methods.getEndTime().call())
      const getLiquidityUnlockTime = Number(await saleContract.methods.getLiquidityUnlockTime().call())
      const unlockTimestampInSeconds = getEndTime + getLiquidityUnlockTime
      const unlockDate = new Date(unlockTimestampInSeconds * 1000)
      const tokenName = await erc20Contract.methods.name().call()
      const tokenSymbol = await erc20Contract.methods.symbol().call()

      const details: SaleDetails = {
        address: saleAddress,
        getTokenContract: tokenAddress,
        balanceOf: await saleContract.methods.balanceOf(account).call(),
        getTokenBalance: await saleContract.methods.getTokenBalance().call(),
        getCollectedETH: await saleContract.methods.getCollectedETH().call(),
        getSoftcap: await saleContract.methods.getSoftcap().call(),
        getHardcap: await saleContract.methods.getHardcap().call(),
        getStatus: await saleContract.methods.getStatus().call(),
        getStartTime: Number(await saleContract.methods.getStartTime().call()),
        getEndTime: Number(await saleContract.methods.getEndTime().call()),
        getLiquidityUnlockTime: Number(await saleContract.methods.getLiquidityUnlockTime().call()),
        unlockDate,
        getExpectedLiquidityETH: await saleContract.methods.getExpectedLiquidityETH().call(),
        getContractETHBalance: await saleContract.methods.getContractETHBalance().call(),
        getSellerAddress: await saleContract.methods.getSellerAddress().call(),
        getLiquidityETH: await saleContract.methods.getLiquidityETH().call(),
        getLiquidityTokenAmount: await saleContract.methods.getLiquidityTokenAmount().call(),
        getLimitPerAccountEnabled: await saleContract.methods.getLimitPerAccountEnabled().call(),
        getLimitPerAccount: await saleContract.methods.getLimitPerAccount().call(),
        getLiquidityPairAddress: await saleContract.methods.getLiquidityPairAddress().call(),
        isWhitelisted: await saleContract.methods.isWhitelisted(account).call(),
        isOwnerOfNFT: await saleContract.methods.isOwnerOfNFT(account).call(),
        isWhitelistActive: await saleContract.methods.isWhitelistActive().call(),
        getVestingEnabled: await saleContract.methods.getVestingEnabled().call(),
        getVestingInterval: await saleContract.methods.getVestingInterval().call(),
        getVestingDuration: await saleContract.methods.getVestingDuration().call(),
        getClaimableTokens: await saleContract.methods.getClaimableTokens(account).call(),
        getTokensPerEth: await saleContract.methods.getTokensPerEth().call(),
        getTokensPerEth2: await saleContract.methods.getTokensPerEth2().call(),
        getTokensPerEthListing: await saleContract.methods.getTokensPerEthListing().call(),
        getLiquidityPercent: await saleContract.methods.getLiquidityPercent().call(),
        getContributions: await saleContract.methods.getContributions(account).call(),
        getUnclaimedTokens: await saleContract.methods.getUnclaimedTokens(account).call(),
        tokenName,
        tokenSymbol
      }

      setSale(details)
/*       console.log(details.getSellerAddress)
      console.log(account) */
      setIsSeller(details.getSellerAddress.toLowerCase() === account.toLowerCase())
      setLoading(false)
    }
  }, [account, saleAddress])

  const startSale = useCallback(async () => {
    if (account) {
      try {
        setLoading(true);
        setModalIsOpen(true);
        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  }, [account])

  const buyTokens = async () => {
    setLoading(true)
    const saleContract = new web3.eth.Contract(SalesAbi, saleAddress)

    let amountInWei = 0

    try {
      if (sale !== undefined && sale !== null) {
        if (
          sale.isWhitelistActive &&
          !(sale.isWhitelisted || sale.isOwnerOfNFT || isSeller) &&
          Number(sale.getCollectedETH) < Number(sale.getSoftcap)
        ) {
          console.error(
            'No puedes comprar tokens en esta etapa ya que no estás en la lista blanca, no posees un NFT y no eres el vendedor.'
          )
          setLoading(false)
          return
        }
        if (tokenAmount) {
          amountInWei = tokenAmount
        }
      }

      // Check if user has enough Ether
      const balanceEther = await web3.eth.getBalance(account)
      // Limit amountInWei to 18 decimals
      amountInWei = Number(amountInWei.toFixed(6))
      if (web3.utils.toBN(balanceEther).lt(web3.utils.toBN(web3.utils.toWei(amountInWei.toString(), 'ether')))) {
        console.error('Not enough Ether for this transaction')
        setLoading(false)
        return
      }

/*       if (web3.utils.toWei(amountInWei.toString(), 'ether') <= 0) {
        amountInWei = 0.000001
      } */

      await saleContract.methods
        .buyTokens()
        .send({ from: account, value: web3.utils.toWei(amountInWei.toString(), 'ether') })

      setConvertedSMR(0)
      loadSale()
      setLoading(false)
      setTokenAmount(null)
    } catch (err) {
      setLoading(false)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const endSale = useCallback(async () => {
    if (account) {
      try {
        setLoading(true)
        const saleContract = new web3.eth.Contract(SalesAbi, saleAddress)
        await saleContract.methods.endSale().send({ from: account })

        loadSale()
        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  }, [account, loadSale, saleAddress])

  const claim = async () => {
    setLoading(true)
    const saleContract = new web3.eth.Contract(SalesAbi, saleAddress)
    await saleContract.methods.claim().send({ from: account })
    loadSale()
    setLoading(false)
  }

  const handleTokenAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAmount = e.target.value;
    const reg = /^\d+$/; // Solo permite números enteros
  
    if (inputAmount === "" || reg.test(inputAmount)) {
      const parsedInput = parseInt(inputAmount, 10); 
  
      if (Number.isNaN(parsedInput)) {
        setTokenAmount(null);
        return;
      }
  
      if (parsedInput > maxTokenAmount) {
        setTokenAmount(maxTokenAmount);
      } else {
        setTokenAmount(parsedInput);
      }
    }
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    // Reemplaza cualquier carácter que no sea un dígito por nada
    e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
  };

  const handleTokenAmountMax = async () => {
    // Obtén el balance del usuario en Ether
    const balanceEther = await web3.eth.getBalance(account)
    // Convierte el balance a formato ETH
    const balanceInETH = parseFloat(web3.utils.fromWei(balanceEther, 'ether'))
    const collectedETH = parseFloat(web3.utils.fromWei(sale?.getCollectedETH, 'ether'))
    const softcap = parseFloat(web3.utils.fromWei(sale?.getSoftcap, 'ether'))

    if (sale && Number(sale?.getCollectedETH) < Number(sale?.getSoftcap)) {
/*       if (sale?.getLimitPerAccountEnabled === true && maxTokenAmountReal >= balanceInETH){
        console.log("hola2")
        if (balanceInETH > 1){
          console.log("hola3")
          setTokenAmount(Math.floor(balanceInETH - 0.3))
        }else{
          console.log("hola4")
          setTokenAmount(Math.floor(0))
        }
      } */
      if (maxTokenAmountReal >= balanceInETH)  {
        if (balanceInETH > 1){
          setTokenAmount(Math.floor(balanceInETH - 0.3))
        }else{
          setTokenAmount(Math.floor(0))
        }
        
      }else if (maxTokenAmountReal > (Number(softcap) - Number(collectedETH))){
        setTokenAmount(Number(softcap) - Number(collectedETH))
      } else {
        setTokenAmount(maxTokenAmountReal)
      }
    } else if (sale && Number(sale?.getCollectedETH) >= Number(sale?.getSoftcap)) {
      if (maxTokenAmountReal >= balanceInETH) {
        if (balanceInETH > 1){
          setTokenAmount(Math.floor(balanceInETH - 0.3))
        }else{
          setTokenAmount(Math.floor(0))
        }
      } else {
        setTokenAmount(maxTokenAmountReal)
      }
    }
  }

  const confirmSale = async () => {
    try {
      setModalIsOpen(false);
      setLoading(true);
      const saleContract = new web3.eth.Contract(SalesAbi, saleAddress)
      await saleContract.methods.startSale().send({ from: account })
      loadSale()
  
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.error('Error starting sale: ', error)
    }
  }

  useEffect(() => {
    const loadAccounts = async () => {
      const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' })
      setAccount(accounts[0])
      const balance = await web3.eth.getBalance(accounts[0])
      setAccountBalance(web3.utils.fromWei(balance, 'ether'))
    }

    const handleAccountsChanged = async (accounts: string[]) => {
      if (accounts.length > 0) {
        setAccount(accounts[0])
        setTokenAmount(null)
        const balance = await web3.eth.getBalance(accounts[0])
        setAccountBalance(web3.utils.fromWei(balance, 'ether'))
      }
    }

    if ((window as any).ethereum) {
      (window as any).ethereum.on('accountsChanged', handleAccountsChanged)
    }

    loadAccounts()
  }, [])

  useEffect(() => {
    loadSale()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, saleAddress])

  useEffect(() => {

/*     setConvertedSMR(0)
    if (tokenAmount) {
      if (sale?.getTokensPerEth && Number(sale.getCollectedETH) < Number(sale.getSoftcap)) {
        const amountInWei = tokenAmount * sale.getTokensPerEth

        setConvertedSMR(amountInWei)
      }
      if (sale?.getTokensPerEth && Number(sale.getCollectedETH) >= Number(sale.getSoftcap)) {
        const amountInWei2 = tokenAmount * sale.getTokensPerEth2
        setConvertedSMR(amountInWei2)
      }
    } */

    if (tokenAmount) {
      const factor = 1e18; // Factor de conversión para pasar de wei a ETH
      if (sale?.getTokensPerEth && Number(sale.getCollectedETH) < Number(sale.getSoftcap)) {
        const amountInEth = tokenAmount * sale.getTokensPerEth / factor;
        setConvertedSMR(amountInEth);
      }
      if (sale?.getTokensPerEth && Number(sale.getCollectedETH) >= Number(sale.getSoftcap)) {
        const amountInEth2 = tokenAmount * sale.getTokensPerEth2 / factor;
        setConvertedSMR(amountInEth2);
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAmount])

  useEffect(() => {
    const fetchBalance = async () => {
      if (sale) {
        let maxTokens = 0

        // Convert all values from Wei to Ether
        const hardcapInEth = web3.utils.fromWei(sale.getHardcap, 'ether')
        const collectedETHInEth = web3.utils.fromWei(sale.getCollectedETH, 'ether')
        const softcapInEth = web3.utils.fromWei(sale.getSoftcap, 'ether')
        const limitPerAccountInEth = web3.utils.fromWei(sale.getLimitPerAccount, 'ether')
        const contributionsInEth = web3.utils.fromWei(sale.getContributions, 'ether')
        const balanceEther = await web3.eth.getBalance(account)
        // Convierte el balance a formato ETH
        const balanceInETH = parseFloat(web3.utils.fromWei(balanceEther, 'ether'))

        // Si el ETH recaudado ha superado el softcap
        if (Number(collectedETHInEth) >= Number(softcapInEth)) {
          maxTokens = roundToSix(Number(hardcapInEth) - Number(collectedETHInEth));
          if (maxTokens >= balanceInETH){
            if (balanceInETH > 1){
              maxTokens = (Math.floor(balanceInETH - 0.3))
            }else{
              maxTokens = (Math.floor(0))
            }
          }
        }
        // Si el ETH recaudado no ha superado el softcap y el límite por wallet está activado
        else if (sale.getLimitPerAccountEnabled) {
          maxTokens = roundToSix(Number(limitPerAccountInEth) - Number(contributionsInEth));
          if(maxTokens > (Number(softcapInEth) - Number(collectedETHInEth))){
            maxTokens = Number(softcapInEth) - Number(collectedETHInEth)
          }
          if (maxTokens >= balanceInETH){
            if (balanceInETH > 1){
              maxTokens = (Math.floor(balanceInETH - 0.3))
            }else{
              maxTokens = (Math.floor(0))
            }
          }
        }
        // Si el ETH recaudado no ha superado el softcap
        else {
          maxTokens = roundToSix(Number(softcapInEth) - Number(collectedETHInEth));
          if (maxTokens >= balanceInETH){
            if (balanceInETH > 1){
              maxTokens = (Math.floor(balanceInETH - 0.3))
            }else{
              maxTokens = (Math.floor(0))
            }
          }
        }

        setMaxTokenAmountReal(maxTokens)
        setMaxTokenAmount(maxTokens)
      }
    };

    fetchBalance();
  }, [sale,account])

  const getDecimals = async (tokenAddress) => {
    const tokenContract = new web3.eth.Contract([
      // Este es el método ABI para el método decimals
      {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [
          {
            "name": "",
            "type": "uint8"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      }
  ], tokenAddress);

  const decimals = await tokenContract.methods.decimals().call();
  return decimals;
}

  const addTokenToMetamask = async () => {
    try {
      const decimales = await getDecimals(sale?.getTokenContract);
      const wasAdded = await (window as any).ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: sale?.getTokenContract,
            symbol: sale?.tokenSymbol,
            decimals: decimales,
            image: 'https://example.com/images/token-image.png' // this can be replaced with your token's logo URL
          }
        }
      })

      if (wasAdded) {
        console.log('Token was successfully added to MetaMask!')
      } else {
        console.log('There was an issue adding the token to MetaMask.')
      }
    } catch (error) {
      console.error('Error adding token to MetaMask: ', error)
    }
  }

  if (!sale) {
    return (
      <div className="salesViewer">
        <h2>Loading Sale...</h2>
        <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
      </div>
    )
  }

// ... Fase 1 ...

const maxVal = sale.getSoftcap.toString();
const currentVal = sale.getCollectedETH.toString();
  
// Parse these values to float and then find the percentage
let percentage = (parseFloat(currentVal) / parseFloat(maxVal)) * 100;
  
// If percentage is greater than 100, set it to 100
if (percentage > 100) {
  percentage = 100;
}
  
// Convert the percentage to a fixed decimal string
const percentageString = percentage.toFixed(0);

// ... Fase 2 ...

const maxVal2 = sale.getHardcap.toString();
const currentVal2 = sale.getCollectedETH.toString();

// Convert these to float
const parsedMaxVal = parseFloat(maxVal);
const parsedMaxVal2 = parseFloat(maxVal2);
const parsedCurrentVal2 = parseFloat(currentVal2);

// Subtract softcap value from hardcap and collected ETH for the second phase
const remainingMaxVal2 = parsedMaxVal2 - parsedMaxVal;
const remainingCurrentVal2 = parsedCurrentVal2 - parsedMaxVal;

// Calculate the percentage for the second phase
let percentage2 = 0;
if (remainingMaxVal2 !== 0) {
  percentage2 = (remainingCurrentVal2 / remainingMaxVal2) * 100;
}

// If percentage is greater than 100, set it to 100
if (percentage2 > 100) {
  percentage2 = 100;
}

// Convert the percentage to a fixed decimal string
const percentageString2 = percentage2.toFixed(0);

  


  return (
    <div className="salesViewer">
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Start Sale"
        className="react-modal-content"
        style={{
        overlay: {
            backgroundColor: 'rgba(28, 28, 28, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            inset: 'auto',
        },
        }}
      >
        <div className='modal-content'>
        <div className="content">
            <img src="/warning.png" className='warning-icon' alt="" />
            <p className="center-text h2">Start Sale</p>
        </div>
        <p className='p'>If you want to activate the whitelist cancel right now and click on the edit icon next to the sale title to edit whitelist.</p>
        <p className='p'>Have you checked the whitelist settings before starting the sale?</p>
        <button type='button' className='confirmBUtton' onClick={confirmSale}>Confirm</button>
        <button type='button' className='cancelBUtton' onClick={() => setModalIsOpen(false)}>Cancel</button>
        </div>

      </ReactModal>
      <img className="imgcoin" src={`/images/coins/${sale.getTokenContract}.png`} alt='Token Icon' onError={(e)=>{(e.target as HTMLImageElement).src='/images/coins/white.png'}} />
      <h2>
        Token {sale.tokenSymbol} Presale
        {isSeller && (
          <span className="edit-sale">
            <Link to={`/edit_sale/${sale.address}`}>
              <img src="images/edit.png" alt="" />
            </Link>
          </span>
        )}
      </h2>
      <p className="backtosale">
        <Link to="/view_sales" className="backLink">
          Back to sales
        </Link>
      </p>

      {loading ? (
        <div>
          <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
        </div>
      ) : (
        <div>

          <div className='margintop5'>
            <div style={{ width: '100%', backgroundColor: '#313131', borderRadius: '15px' }}>
              <div style={{ width: `${percentageString}%`, backgroundColor: '#1a98ff', height: '30px', borderRadius: '15px' }} />
            </div>
            <span className='marginleft5'>Phase 1: {`${percentageString}%`}</span>
          </div>
          {parseFloat(currentVal) >= parseFloat(maxVal) && (
            <div className='margintop5'>
              <div style={{ width: '100%', backgroundColor: '#313131', borderRadius: '15px' }}>
                <div style={{ width: `${percentageString2}%`, backgroundColor: '#1a98ff', height: '30px', borderRadius: '15px' }} />
              </div>
              <span className='marginleft5'>Phase 2: {`${percentageString2}%`}</span>
            </div>
          )}

          <div className="cardContainer2">
            <div className="card3" key={sale.address}>
              <div className="card-content2">
                <p>
                  <span className="span">Token Name:</span>
                  {sale.tokenName}
                </p>
                <p>
                  <span className="span">Token Symbol:</span>
                  {sale.tokenSymbol}
                </p>
                <CopyToClipboard text={sale.getTokenContract} onCopy={() => setCopyMessage('Token Contract copied!')}>
                  <p title={sale.getTokenContract}>
                    <span className="span">Token Contract:</span>
                    <span>
                      <button type="button" className="addMetaMask2" onClick={addTokenToMetamask}>
                        <img className="metamask2" src="images/metamask2.png" alt="" />
                      </button>
                      <img src="/images/copy.png" alt="copy" style={{ width: '18px' }} />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://explorer.evm.shimmer.network/address/${sale.getTokenContract}/`}
                      >
                        <img src="/images/link.png" alt="copy" style={{ width: '18px' }} />
                      </a>
                    </span>
                  </p>
                </CopyToClipboard>
                <CopyToClipboard text={sale.address} onCopy={() => setCopyMessage('Sale Contract copied!')}>
                  <p title={sale.address}>
                    <span className="span">Sale Contract:</span>
                    <span>
                      <img src="/images/copy.png" alt="copy" style={{ width: '18px' }} />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://explorer.evm.shimmer.network/address/${sale.address}/`}
                      >
                        <img src="/images/link.png" alt="copy" style={{ width: '18px' }} />
                      </a>
                    </span>
                  </p>
                </CopyToClipboard>
                <CopyToClipboard text={sale.getSellerAddress} onCopy={() => setCopyMessage('Seller address copied!')}>
                  <p title={sale.getSellerAddress}>
                    <span className="span">Seller address:</span>
                    <span>
                      <img src="/images/copy.png" alt="copy" style={{ width: '18px' }} />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://explorer.evm.shimmer.network/address/${sale.getSellerAddress}/`}
                      >
                        <img src="/images/link.png" alt="copy" style={{ width: '18px' }} />
                      </a>
                    </span>
                  </p>
                </CopyToClipboard>
                <p>
                  <span className="span">Collected SMR:</span>
                  {parseFloat(web3.utils.fromWei(sale.getCollectedETH, 'ether')).toFixed(0)} SMR
                </p>
                <p>
                  <span className="span">Softcap:</span>
                  {parseFloat(web3.utils.fromWei(sale.getSoftcap, 'ether')).toFixed(0)} SMR
                </p>
                <p>
                  <span className="span">Hardcap:</span>
                  {parseFloat(web3.utils.fromWei(sale.getHardcap, 'ether')).toFixed(0)} SMR
                </p>
                <p>
                  <span className="span">Phase 1 # 1 SMR:</span>
                  {sale.getTokensPerEth / 1e18} {sale.tokenSymbol}
                </p>
                <p>
                  <span className="span">Phase 2 # 1 SMR:</span>
                  {sale.getTokensPerEth2 / 1e18} {sale.tokenSymbol}
                </p>
                <p>
                  <span className="span">Listing ## 1 SMR:</span>
                  {sale.getTokensPerEthListing / 1e18} {sale.tokenSymbol}
                </p>
                <p>
                  <span className="span">Start Date:</span>
                  {new Date(sale.getStartTime * 1000).toLocaleString()}
                </p>
                <p>
                  <span className="span">End Date:</span>
                  {new Date(sale.getEndTime * 1000).toLocaleString()}
                </p>
              </div>
            </div>
            <div className="card3">
              <div className="card-content2">
                <p>
                  <span className="span">Liquidity Unlock:</span>
                  {sale.unlockDate.toLocaleString()}
                </p>
                <p>
                  <span className="span">Liquidity Percent:</span>
                  {sale.getLiquidityPercent}%
                </p>
                {(BigInt(sale.getCollectedETH) < BigInt(sale.getSoftcap)) &&(
                <p>
                  <span className="span">Limit Per Account Enabled:</span>
                  {sale.getLimitPerAccountEnabled ? 'Yes' : 'No'}
                </p>
                )}
                {sale.getLimitPerAccountEnabled && (BigInt(sale.getCollectedETH) < BigInt(sale.getSoftcap)) && (
                  <>
                    <p>
                      <span className="span">Limit Per Account:</span>
                      {parseFloat(web3.utils.fromWei(sale.getLimitPerAccount, 'ether')).toFixed(0)} SMR
                    </p>
                  </>
                )}
                {(BigInt(sale.getCollectedETH) < BigInt(sale.getSoftcap)) &&(
                <p>
                  <span className="span">Whitelist Active:</span>
                  {sale.isWhitelistActive ? 'Yes' : 'No'}
                </p>
                )}
                {sale.isWhitelistActive && (BigInt(sale.getCollectedETH) < BigInt(sale.getSoftcap)) && (
                  <>
                    <p>
                      <span className="span">Whitelisted:</span> {sale.isWhitelisted ? 'Yes' : 'No'}
                    </p>
                    <p>
                      <span className="span">NFT Owner:</span>
                      {sale.isOwnerOfNFT ? 'Yes' : 'No'}
                    </p>
                  </>
                )}
                <p>
                  <span className="span">Vesting Active:</span>
                  {sale.getVestingEnabled ? 'Yes' : 'No'}
                </p>
                {sale.getVestingEnabled && (
                  <>
                    <p>
                      <span className="span">Vesting Interval:</span>
                      {Math.round(sale.getVestingInterval / 86400)} days
                    </p>
                    <p>
                      <span className="span">Duration of Vesting:</span>
                      {Math.round(sale.getVestingDuration / 86400)} days
                    </p>
                  </>
                )}
                {sale.getStatus === '1' && (
                  <>
                    <p className='line-background'>
                      <span className="span">{sale.tokenSymbol} balance:</span>
                      {parseFloat(web3.utils.fromWei(sale.balanceOf, 'ether')).toFixed(2)} {sale.tokenSymbol}
                    </p>
                    <p>
                      <span className="span">SMR Spent:</span>
                      {parseFloat(web3.utils.fromWei(sale.getContributions, 'ether')).toFixed(0)} SMR
                    </p>
                  </>
                )}
                {sale.getStatus === '3' && (
                  <>
                    <p>
                      <span className="span">SMR Spent:</span>
                      {parseFloat(web3.utils.fromWei(sale.getContributions, 'ether')).toFixed(0)} SMR
                    </p>
                  </>
                )}
                {sale.getVestingEnabled && sale.getStatus === '2' && (
                  <>
                    <p>
                      <span className="span">Unclaimed Tokens:</span>
                      {parseFloat(web3.utils.fromWei(sale.getUnclaimedTokens, 'ether')).toFixed(2)} {sale.tokenSymbol}
                    </p>
                  </>
                )}
                {sale.getStatus === '2' && (
                  <p className='line-background'>
                    <span className="span">Claimable Tokens:</span>
                    {parseFloat(web3.utils.fromWei(sale.getClaimableTokens, 'ether')).toFixed(2)} {sale.tokenSymbol}
                  </p>
                )}
              </div>
              {sale.getStatus === '0' && isSeller && Date.now() > sale.getStartTime * 1000 && (
                <button type="button" className="startButton" onClick={() => startSale()}>
                  Start Sale
                </button>
              )}
              {sale.getStatus === '1' && sale.getCollectedETH !== sale.getHardcap && new Date(sale.getEndTime * 1000) > new Date() && (
                <div className="card-content3">
                  <div>
                    <input
                      type="number"
                      min="0"
                      className="inputField"
                      placeholder="Enter Amount in SMR"
                      value={tokenAmount === null ? '' : tokenAmount}
                      onChange={handleTokenAmountChange}
                      onInput={handleInput}
                      onClick={(e) => {
                        if (tokenAmount === 0) {
                          setTokenAmount(null)
                        }
                      }}
                    />
                    <button type="button" className="maxbutton" onClick={handleTokenAmountMax}>
                      MAX
                    </button>
                  </div>
                  <button
                    type="button"
                    className="buyButton"
                    onClick={() => buyTokens()}
                    disabled={
                      convertedSMR === null ||
                      convertedSMR <= 0 ||
                      (tokenAmount ? tokenAmount > accountBalance : true) ||
                      (sale?.isWhitelistActive && sale?.getCollectedETH < sale?.getSoftcap && !(sale?.isWhitelisted || sale?.isOwnerOfNFT || isSeller))
                    }
                  >
                    Buy {sale?.tokenSymbol}
                  </button>
                </div>
              )}
              {(sale.getStatus === '1' && (BigInt(sale.getCollectedETH) >= BigInt(sale.getHardcap))) && (
                  <button type="button" className="endButton" onClick={endSale}>
                      End Sale
                  </button>
              )}
              {(sale.getStatus === '1' && ((new Date(sale.getEndTime * 1000) < new Date()))) && !(sale.getStatus === '1' && (BigInt(sale.getCollectedETH) >= BigInt(sale.getHardcap))) && (
                  <button type="button" className="endButton" onClick={endSale}>
                      End Sale
                  </button>
              )}
              {sale.getStatus === '2' && parseFloat(web3.utils.fromWei(sale.getClaimableTokens, 'ether')) > 0 && (
                <button type="button" className="claimButton" onClick={claim}>
                  Claim {sale.tokenSymbol}
                </button>
              )}
              {sale.getStatus === '3' && sale.getContributions > 0 && (
                <div>
                  <button type="button" className="claimButton" onClick={claim}>
                    Claim SMR
                  </button>
                  <p className="color-red">The sale has been cancelled and you can now recover your spent SMRs.</p>
                </div>
              )}
  {/*             {sale.isWhitelistActive && sale.getCollectedETH < sale.getSoftcap && (
                  <p className="color-red">To be able to buy at this stage you need to be on Whitelist or be a holder of 1 NFT.</p>
              )} */}
              <div className="card-content2">
                {sale.getStatus === '1' && (
                  <p>
                    <span className="span">Amount in {sale.tokenSymbol}:</span>
                    {convertedSMR.toFixed(2)} {sale.tokenSymbol}
                  </p>
                )}
                {sale.getStatus === '1' &&
                  sale?.isWhitelistActive && sale?.getCollectedETH < sale?.getSoftcap &&
                  !(sale?.isWhitelisted || sale?.isOwnerOfNFT || isSeller) && (
                    <p className="color-red">
                      You can&apos;t buy because whitelist is active and you are not on the Whitelist and you don&apos;t
                      own NFT Genesis or Standard.
                    </p>
                  )}
                  {sale?.getLimitPerAccountEnabled === true && 
                    BigInt(sale?.getLimitPerAccount) === BigInt(sale?.getContributions) &&
                    sale?.getCollectedETH < sale?.getSoftcap && (
                    <p className="color-red">You have reached your purchase limit.</p>
                  )}
                {sale.getStatus === '2' && (
                  <button
                    type="button"
                    className="claimButton"
                    onClick={() => {
                      window.location.href = `/#/swap?outputCurrency=${sale.getTokenContract}`
                    }}
                  >
                    <span className="span text">Swap {sale.tokenSymbol}:</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SaleDetails
