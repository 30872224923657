import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Web3 from 'web3';
import LaunchpadFactoryAbi from './LaunchpadFactoryAbi.json';
import SalesAbi from './SalesAbi.json';
import ERC20Abi from './ERC20Abi.json';
import './viewSales.css';

let web3;
if ((window as any).ethereum) {
  (window as any).ethereum.request({ method: 'eth_requestAccounts' });
  web3 = new Web3((window as any).ethereum);
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.');
  web3 = new Web3();
}

const LaunchpadFactoryContractAddress = '0xdbdB5abF89F539Fc6e758D00a464a47277221f0F'; // Place the deployed contract address
const LaunchpadFactoryContract = new web3.eth.Contract(LaunchpadFactoryAbi, LaunchpadFactoryContractAddress);

const statusMap = {
  '0': 'Waiting to start',
  '1': 'In Progress',
  '2': 'Completed',
  '3': 'Canceled'
};

interface SaleDetails {
  address: string;
  getTokenContract: string;
  getCollectedETH: string;
  getSoftcap: string;
  getHardcap: string;
  getStatus: string;
  getStartTime: number;
  getEndTime: number;
  getClaimableTokens: string;
  tokenName: string;
  tokenSymbol: string;
}

const getStatusClass = (status: string) => {
  switch (status) {
    case '0':
      return 'status-waiting';
    case '1':
      return 'status-started';
    case '2':
      return 'status-completed';
    case '3' :
      return 'status-canceled';
    default:
      return '';
  }
};

const isSaleActive = (status: string) => {
  return status === '1' || status === '2';
};

const SalesViewerDApp = () => {
  const [account, setAccount] = useState('');
  const [sales, setSales] = useState<SaleDetails[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const loadSales = useCallback(async () => {
    if (account) { // Only attempt to load sales if account is not null
      setLoading(true);
      const salesList = await LaunchpadFactoryContract.methods.getSales().call({ from: account });
      const detailedSales: SaleDetails[] = await Promise.all(salesList.map(async (saleAddress): Promise<SaleDetails> => {
        const saleContract = new web3.eth.Contract(SalesAbi, saleAddress);
        const tokenAddress = await saleContract.methods.getTokenContract().call();
        const erc20Contract = new web3.eth.Contract(ERC20Abi, tokenAddress);

        const tokenName = await erc20Contract.methods.name().call();
        const tokenSymbol = await erc20Contract.methods.symbol().call();

        const getEndTime = Number(await saleContract.methods.getEndTime().call());
        const getLiquidityUnlockTime = Number(await saleContract.methods.getLiquidityUnlockTime().call());
        const unlockTimestampInSeconds = getEndTime + getLiquidityUnlockTime;
        const unlockDate = new Date(unlockTimestampInSeconds * 1000);

        const details: SaleDetails = {
          address: saleAddress,
          getTokenContract: await saleContract.methods.getTokenContract().call(),
          getCollectedETH: await saleContract.methods.getCollectedETH().call(),
          getSoftcap: await saleContract.methods.getSoftcap().call(),
          getHardcap: await saleContract.methods.getHardcap().call(),
          getStatus: await saleContract.methods.getStatus().call(),
          getStartTime: Number(await saleContract.methods.getStartTime().call()), // Convert to number
          getEndTime,
          getClaimableTokens: await saleContract.methods.getClaimableTokens(account).call(),
          tokenName,
          tokenSymbol,
          // ... add all the other methods here as well...
        };
        return details;
      }));

      setSales(
        detailedSales.sort((a, b) => {
          const statusPriorities: { [key: string]: number } = { '1': 0, '0': 1, '2': 2, '3': 3 };
      
          const statusA = statusPriorities[a.getStatus];
          const statusB = statusPriorities[b.getStatus];
      
          if (statusA < statusB) return -1;
          if (statusA > statusB) return 1;
      
          // Ordenar las ventas "En curso" y "Esperando para empezar" por fecha de finalización
          if (statusA === 0 || statusA === 1) {
            const endTimeA = a.getEndTime;
            const endTimeB = b.getEndTime;
            if (endTimeA < endTimeB) return -1;
            if (endTimeA > endTimeB) return 1;
          }
          // Ordenar las ventas "Completadas" y "Canceladas" por fecha de inicio
          else if (statusA === 2 || statusA === 3) {
            const startTimeA = a.getStartTime;
            const startTimeB = b.getStartTime;
            if (startTimeA < startTimeB) return -1;
            if (startTimeA > startTimeB) return 1;
          }
      
          return 0;
        })
      );      

      setSales(detailedSales);
/*       console.log(detailedSales); */
      setLoading(false);
    }
  }, [account]);

  const startSale = useCallback(async (sale: SaleDetails) => {
    if (account) {
      try {
        setLoading(true);
        const saleContract = new web3.eth.Contract(SalesAbi, sale.address);
        await saleContract.methods.startSale().send({ from: account });

        loadSales();
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
  }, [account, loadSales]);
  

  useEffect(() => {
    const loadAccounts = async () => {
      const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
      setAccount(accounts[0]);
    };

    const handleAccountsChanged = (accounts: string[]) => {
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
    };
  
    if ((window as any).ethereum) {
      (window as any).ethereum.on('accountsChanged', handleAccountsChanged);
    }
  
    loadAccounts();
  }, []); // Load the account only when the component mounts
  
  useEffect(() => {  
    loadSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, setSales]);  // Add account and setSales as dependencies to refetch whenever these change

return (
  <div className="salesViewer">
    <h2>Sales <Link type='button' to="/create_sale" className='selectorNFT3'>Create Sale</Link></h2>
    {loading ? (
      <div>
        <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
      </div>
    ) : (
      <div className="cardContainer">
        {sales.map((sale) => (
          <div className="card2" key={sale.address}>
            <div className="card-content">
              <div className='divimgcoin2'>
                <img className="imgcoin2" src={`/images/coins/${sale.getTokenContract}.png`} alt='Token Icon' 
                  onError={(e)=>{(e.target as HTMLImageElement).src='/images/coins/white.png'}} />
              </div>

              <p><span>Token Name:</span>{sale.tokenName}</p>
              <p><span>Token Symbol:</span>{sale.tokenSymbol}</p>
              <p><span>Collected SMR:</span>{parseFloat(web3.utils.fromWei(sale.getCollectedETH, 'ether'))} SMR</p>
              <p><span>Softcap:</span>{parseFloat(web3.utils.fromWei(sale.getSoftcap, 'ether'))} SMR</p>
              <p><span>Hardcap:</span>{parseFloat(web3.utils.fromWei(sale.getHardcap, 'ether'))} SMR</p>
              <p className={getStatusClass(sale.getStatus)}><span>Status:</span>{statusMap[sale.getStatus] || 'Estado desconocido'}</p>
              <p><span>Start Date:</span>{new Date(sale.getStartTime * 1000).toLocaleString()}</p>
              <p><span>End Date:</span>{new Date(sale.getEndTime * 1000).toLocaleString()}</p>
            </div>
            {(sale.getStatus === '0' ||sale.getStatus === '1' || (sale.getStatus === '2' && sale.getClaimableTokens > '0')) && (
              <button 
                type='button'
                className="buttonsale"
                onClick={() => history.push(`/sale/${sale.address}`)} 
              >
                Enter to Sale
              </button>
            )}
            {sale.getClaimableTokens === '0' && sale.getStatus === '2' && (
              <button 
              type='button'
              className='buttonsale disabled-button-finished'
              onClick={() => history.push(`/sale/${sale.address}`)}
            >
              Sale Completed
            </button>
            )}
            {sale.getClaimableTokens === '0' && sale.getStatus === '3' && (
              <button 
              type='button'
              className='buttonsale disabled-button-canceled'
              onClick={() => history.push(`/sale/${sale.address}`)}
            >
              Sale Canceled
            </button>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
);

};

export default SalesViewerDApp;
