import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import SalesAbi from './SalesAbi.json';
import './editSale.css';

// Define the interface
interface RouteParams {
  saleAddress: string;
}

const EditSale = () => {
  // Use the interface with useParams
  const { saleAddress } = useParams<RouteParams>();
  const [account, setAccount] = useState<string | null>(null);
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [saleContract, setSaleContract] = useState<any | null>(null);
  const [whitelistActive, setWhitelistActive] = useState<boolean>(false);
  const [addressToAdd, setAddressToAdd] = useState<string>('');
  const [whitelistedAddresses, setWhitelistedAddresses] = useState<string[]>([]);
  const [saleStatus, setSaleStatus] = useState<number | null>(null);
  const [liquidityUnlockTime, setLiquidityUnlockTime] = useState<number | null>(null);


  const cancelSale = async () => {
    try {
      if (saleContract && account) {
        // Obten el estado actual de la venta
        const status = await saleContract.methods.getStatus().call();
  
        // Verifica si el estado es PENDING (0) o ACTIVE (1)
        if (status === '0' || status === '1') {
          await saleContract.methods.cancelSale().send({ from: account });
          // Aquí también puedes verificar nuevamente el estado de la venta y actualizar el estado del componente si es necesario
          const updatedStatus = await saleContract.methods.getStatus().call();
          setSaleStatus(parseInt(updatedStatus, 10));
        } else {
          console.log('La venta no puede ser cancelada. El estado actual de la venta es:', status);
        }
      }
    } catch (error) {
      console.error('Error cancelling sale:', error);
    }
  };

  const addToWhitelist = async () => {
    try {
      if (saleContract && account && addressToAdd) {
        // Split input by either comma or space
        const addresses = addressToAdd.split(/[, ]+/);
  
        // Remove any duplicate addresses
        const uniqueAddresses = [...new Set(addresses)];
  
        // Verify that all addresses are valid Ethereum addresses
        const isValidAddress = (address: string) => web3 && web3.utils.isAddress(address);
        const invalidAddresses = uniqueAddresses.filter(address => !isValidAddress(address));
        if (invalidAddresses.length > 0) {
          console.error(`These addresses are not valid: ${invalidAddresses.join(', ')}`);
          return;
        }
  
        await saleContract.methods.addToWhitelist(uniqueAddresses).send({ from: account });
        checkWhitelistStatus();
  
        // Fetch whitelist addresses after successfully adding new addresses
        fetchWhitelist();
  
        // Clear the textarea after successful submission
        setAddressToAdd('');
      }
    } catch (error) {
      console.error('Error adding to whitelist:', error);
    }
  };   

  const withdrawLiquidity = async () => {
    try {
      if (saleContract && account) {
        await saleContract.methods.withdrawLiquidity().send({ from: account });
      }
    } catch (error) {
      console.error('Error withdrawing liquidity:', error);
    }
  };  
  
  const toggleWhitelist = async () => {
    try {
      console.log(saleContract);
      console.log(account);
      if (saleContract && account) {
        const receipt = await saleContract.methods.toggleWhitelist().send({ from: account });
        if (receipt.status) {
          checkWhitelistStatus();
        }
      }
    } catch (error) {
      console.error('Error toggling whitelist:', error);
    }
  };

  const fetchWhitelist = useCallback(async () => {
    if (saleContract) {
      const addresses = await saleContract.methods.getWhitelist().call();
      setWhitelistedAddresses(addresses);
    }
  }, [saleContract]);  
  
  const checkWhitelistStatus = useCallback(async () => {
    if (saleContract) {
      setWhitelistActive(false); // reset the state
      const status = await saleContract.methods.isWhitelistActive().call();
      setWhitelistActive(status);
    }
  }, [saleContract]);  

  useEffect(() => {
    const init = async () => {
        if ((window as any).ethereum) {
            const web3Instance = new Web3((window as any).ethereum);
            const accounts = await web3Instance.eth.getAccounts();
            setAccount(accounts[0]);
            setWeb3(web3Instance);
            const saleContractInstance = new web3Instance.eth.Contract(SalesAbi as AbiItem[], saleAddress);
            setSaleContract(saleContractInstance);
        } else {
            console.error('Ethereum provider not found. Make sure MetaMask is installed.');
        }
    }
    init();
}, [saleAddress]);

useEffect(() => {
  const fetchLiquidityUnlockTime = async () => {
    if (saleContract) {
      const unlockTime = await saleContract.methods.getLiquidityUnlockTime().call();
      setLiquidityUnlockTime(unlockTime);
    }
  };

  fetchLiquidityUnlockTime();
}, [saleContract]);


  // Add a new useEffect to call checkWhitelistStatus after saleContract has been set
  useEffect(() => {
    if (saleContract) {
      checkWhitelistStatus();
    }
  }, [saleContract, checkWhitelistStatus]);

  useEffect(() => {
    fetchWhitelist();
  }, [fetchWhitelist]);

  useEffect(() => {
    const fetchSaleStatus = async () => {
      if (saleContract) {
        const status = await saleContract.methods.getStatus().call();
        setSaleStatus(parseInt(status, 10)); // asumimos que el estado se devuelve como una cadena
      }
    };
  
    fetchSaleStatus();
  }, [saleContract]);

  return (
    <div className="editSaleContainer">
      <h2 className="title">Edit Sale</h2>
      <p className='backtosale'>
          <Link to={`/sale/${saleAddress}`} className='backLink'>Back to sale</Link>
      </p>
      <div className='details'>
        <p className='controls'>
          Status of the sale:
          {saleStatus === null && ' Loading...'}
          {saleStatus === 0 && ' Pending'}
          {saleStatus === 1 && ' Active'}
          {saleStatus === 2 && ' Finished'}
          {saleStatus === 3 && ' Cancelled'}
        </p>
      </div>
      {(saleStatus === 0 || saleStatus === 1) &&
        <div>
          <button type='button' onClick={cancelSale} className='cancel-sale'>Cancel Sale</button>
          <div className="details">
            {/* <p>Sale Address: {saleAddress}</p>
            <p>Account: {account}</p> */}
            <p>Whitelist is {whitelistActive ? 'ACTIVE' : 'INACTIVE'}</p>
          </div>
          <div className="controls">
            <button type='button' onClick={toggleWhitelist} className="toggleButton">Toggle Whitelist</button>
            <div className="inputGroup">
              <textarea 
                value={addressToAdd} 
                onChange={e => setAddressToAdd(e.target.value)} 
                placeholder="Addresses to add" 
                className="inputField" 
                rows={2} // adjust based on your preference
              />
              <button type='button' onClick={addToWhitelist} className="addButton">Add to Whitelist</button>
            </div>
          </div>
          <div className="whitelist">
            {whitelistedAddresses.map((address) => (
              <div key={address}>{address}</div>
            ))}
          </div>
        </div>
      }
      {saleStatus === 2 &&
        <div className='details'>
          <p className='controls'>Liquidity unlock time: 
            {liquidityUnlockTime ? new Date(liquidityUnlockTime * 1000).toLocaleString() : 'Loading...'}
          </p>
          <button 
            type='button' 
            onClick={withdrawLiquidity} 
            className='withdraw-liquidity' 
            disabled={liquidityUnlockTime ? Date.now() / 1000 < liquidityUnlockTime : true}
          >
            Withdraw Liquidity
          </button>
        </div>
      }
    </div>
  );
}

export default EditSale;
