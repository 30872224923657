import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'

const StyledNav = styled.div`
  margin-bottom: 40px;
`

const StyledButtonMenuItem = styled(ButtonMenuItem)`
  && {
    border-radius: 50px;  
    padding: 10px 20px;  
    color: white;

    &.active {
      background-color: #1a98ff;
      color: #000000;
    }

    &.inactive:hover {
      font-weight:500;
    }


    &.inactive {
      background-color: #313131;
    }
  }
`

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  const TranslateString = useI18n()
  const location = useLocation()

  return (
    <StyledNav>
      <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
        <StyledButtonMenuItem className={location.pathname === "/swap" ? 'active' : 'inactive'} id="swap-nav-link" to="/swap" as={Link}>
          {TranslateString(1142, 'Swap')}
        </StyledButtonMenuItem>
        <StyledButtonMenuItem className={location.pathname === "/pool" ? 'active' : 'inactive'} id="pool-nav-link" to="/pool" as={Link}>
          {TranslateString(262, 'Liquidity')}
        </StyledButtonMenuItem>
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
