import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import QuestionHelper from 'components/QuestionHelper';
import useI18n from 'hooks/useI18n'
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import stakingABI from './stakingNFTABI.json';
import originalNFTABI from './originalNFTABI.json';
import mirrorNFTABI from './mirrorNFTABI.json';
import './styles.css';

ReactModal.setAppElement('#root');

const StakingPage = () => {
    const [accounts, setAccounts] = useState<string[]>([]);
    const [originalNFTs, setOriginalNFTs] = useState<(string | number)[]>([]);
    const [mirrorNFTs, setMirrorNFTs] = useState<(string | number)[]>([]);
    const [stakingContract, setStakingContract] = useState<Contract | null>(null);
    const [originalNFTContract, setOriginalNFTContract] = useState<Contract | null>(null);
    const [mirrorNFTContract, setMirrorNFTContract] = useState<Contract | null>(null);
    const [loading, setLoading] = useState(false);
    const [rewards, setRewards] = useState<Record<string, number>>({});
    const [modalOpen, setModalOpen] = useState(false);
    const [unstakingId, setUnstakingId] = useState<number | string | null>(null);
    const [hasNFTsEnded, setHasNFTsEnded] = useState<boolean>(false);
    const [accountsEnded, setAccountsEnded] = useState<boolean>(false);
    const [hasNFTs, setHasNFTs] = useState<boolean>(false);
    const TranslateString = useI18n()

    const openModalWithId = (id: number | string) => {
        setUnstakingId(id);
        setModalOpen(true);
    };  

    const originalNFTAddress = '0x8F6A97A176A61bc7516023aCa67A4931B638369C';
    const stakingNFTAddress = '0x317fd00299F75091554b29A7938f6aedc7bB9027';
    const mirrorNFTAddress = '0x437DC98899b1a199C48c41aE112dA766E7febb7F';

    // Create a variable to hold the provider.
    const provider = useRef(null as any);

    const loadWeb3 = async () => {
        if (window.ethereum) {
            provider.current = window.ethereum;
            window.web3 = new Web3(provider.current);
            await provider.current.request({ method: 'eth_requestAccounts' });
        } else {
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    };

    const loadAccounts = async () => {
        const accts = await provider.current.request({ method: 'eth_accounts' });
        setAccounts(accts);
        setAccountsEnded(true);
    };

    const userHasNFT = useCallback(async () => {
        try {
            let numOriginalNFTs = 0;
            let numMirrorNFTs = 0;
            if (originalNFTContract) {
                numOriginalNFTs = await originalNFTContract.methods.balanceOf(accounts[0]).call();
            }
            if (mirrorNFTContract) {
                numMirrorNFTs = await mirrorNFTContract.methods.balanceOf(accounts[0]).call();
            }
            setHasNFTs(numOriginalNFTs + numMirrorNFTs > 0);
        } catch (error) {
            console.error('Error checking if user has NFT: ', error);
            setHasNFTs(false);
        }
        setHasNFTsEnded(true);
    }, [accounts, originalNFTContract, mirrorNFTContract]);

    const loadOriginalNFTs = useCallback(async () => {
        if (!originalNFTContract || accounts.length === 0) return;
    
        setLoading(true);

        const numNFTs = await originalNFTContract.methods.balanceOf(accounts[0]).call();
    
        // Build an array of promises
        const promises = Array.from({ length: numNFTs }, (_, i) =>
            originalNFTContract.methods.tokenOfOwnerByIndex(accounts[0], i).call()
        );
    
        // Resolve all promises simultaneously
        const tokens = await Promise.all(promises);
        setOriginalNFTs(tokens);
        await userHasNFT();
        setLoading(false);
    }, [accounts, originalNFTContract,userHasNFT]);

    const loadMirrorNFTs = useCallback(async () => {
        if (!mirrorNFTContract || accounts.length === 0) return;
    
        const numNFTs = await mirrorNFTContract.methods.balanceOf(accounts[0]).call();
    
        // Build an array of promises
        const promises = Array.from({ length: numNFTs }, (_, i) =>
            mirrorNFTContract.methods.tokenOfOwnerByIndex(accounts[0], i).call()
        );
    
        // Resolve all promises simultaneously
        const tokens = await Promise.all(promises);
        setMirrorNFTs(tokens);
        await userHasNFT();
    }, [accounts, mirrorNFTContract, userHasNFT]);

    const stakeNFT = async (id: string | number) => {
        if (!stakingContract) return;
        try {
            setLoading(true);
            if (!originalNFTContract){
                return;
            }
            
            // Comprobar si el NFT ya está aprobado para el contrato de staking
            const isAlreadyApproved = await originalNFTContract.methods.isApprovedForAll(accounts[0], stakingNFTAddress).call();

            if (!isAlreadyApproved) {
                /* alert(`Approved Address: ${approvedAddress}, Staking Address: ${stakingNFTAddress}, Account: ${accounts[0]}, Token ID: ${tokenId}`); */
                const result = await originalNFTContract.methods.setApprovalForAll(stakingNFTAddress, true).send({ from: accounts[0] });
                console.log('Approve transaction sent:', result);
                
                // Only stake if approve was successful
                if (result.status === true) {
                    await stakingContract.methods.stake(id).send({ from: accounts[0] });
                }
            } else {
                console.log('NFT already approved for this contract');
                await stakingContract.methods.stake(id).send({ from: accounts[0]});
            }

            loadOriginalNFTs();
            loadMirrorNFTs();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error staking NFT: ', error);
        }
    };

    const unstakeNFT = async (id: string | number) => {
        if (!stakingContract) return;
        try {
            setLoading(true);
            await stakingContract.methods.unstake(String(id)).send({ from: accounts[0], gas: 300000 });
            loadMirrorNFTs();
            loadOriginalNFTs();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error unstaking NFT: ', error);
        }
    };

    const getRewards = useCallback(async (id: string | number) => {
        let reward = 0;
        if (!stakingContract || accounts.length === 0) return reward;
    
        try {
            setLoading(true);
            reward = await stakingContract.methods.checkReward(String(id)).call();
            reward = window.web3.utils.fromWei(reward, 'ether');
        } catch (error) {
            console.error('Error getting rewards: ', error);
        }
        setLoading(false);
        return reward;
    }, [stakingContract, accounts]);

    useEffect(() => {
        const updateRewards = async () => {
            const newRewards: Record<string, number> = {};
    
            await Promise.all(
                mirrorNFTs.map(async (id) => {
                    newRewards[String(id)] = await getRewards(id);
                })
            );
    
            setRewards(newRewards);
        };
    
        updateRewards();
    }, [mirrorNFTs, getRewards]);

    useEffect(() => {
        loadWeb3();
        loadAccounts();
    }, []);

    useEffect(() => {
        const loadContracts = async () => {
            if (!window.web3) return;
            const web3 = window.web3;
    
            const originalNFTContractInstance = new web3.eth.Contract(originalNFTABI, originalNFTAddress);
            setOriginalNFTContract(originalNFTContractInstance);
    
            const stakingContractInstance = new web3.eth.Contract(stakingABI, stakingNFTAddress);
            setStakingContract(stakingContractInstance);

            const mirrorNFTContractInstance = new web3.eth.Contract(mirrorNFTABI, mirrorNFTAddress);
            setMirrorNFTContract(mirrorNFTContractInstance);
        };
    
        loadContracts();
    }, [accounts]);

    useEffect(() => {
        if (originalNFTContract && accounts.length > 0) {
            loadOriginalNFTs();
        }
    }, [accounts, originalNFTContract, loadOriginalNFTs]);

    useEffect(() => {
        if (mirrorNFTContract && accounts.length > 0) {
            loadMirrorNFTs();
        }
    }, [accounts, mirrorNFTContract, loadMirrorNFTs]);

    useEffect(() => {
        const ethereum = window.ethereum;
        const handleAccountsChanged = async (newAccounts: string[]) => {
            setAccounts(newAccounts);
            await userHasNFT();
        };
    
        if (ethereum && ethereum.on) {
            ethereum.on('accountsChanged', handleAccountsChanged);
        }
    
        return () => {
            if (ethereum) {
                (ethereum as any).removeAllListeners('accountsChanged');
            }
        };        
    }, [userHasNFT]);
    
    return (
        <div className='contenedorPrincipal'>
            <h2>Staking
                <QuestionHelper
                    text={TranslateString(
                        300,
                        'NFT Staking is our module that allows the community to stake their Genesis and Standard NFTs. Staking will give you more ZERO tokens every hour, plus it will give you access to the Fee Sharing and Fee Reward modules among other benefits.'
                    )}
                />
            </h2>
            <div className='buttonContainer'>
                <Link type='button' to="/genesis_staking" className='selectorNFT3'>Genesis</Link>
                {/* <Link type='button' to="/standard_staking" className='selectorNFT3'>Standard</Link> */}
                <span className='selectorNFT'>Airdrop</span>
            </div>
            {loading || !hasNFTsEnded || !accountsEnded ? (
                <div>
                <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
            </div>
            ) : hasNFTs && accounts ? (
            <>
            <div>
                <div className='contenedorNFTs'>
                    {originalNFTs.map((id) => (
                        <div key={id} className="token-container">
                            <div className="claim-gif">
                                <img src="NFTairdrop.png" loading="lazy" alt="" />
                            </div>
                            <p className="token-id">NFT {id}</p>
                            <button type='button' className='bluebutton' onClick={() => stakeNFT(id)}>Stake</button>
                            <div className='rewards-container'>
                                <span className='reward-id'>{rewards[String(id)]}</span>
                                {/* <img className='reward-icon' src="/favicon2.png" alt="zero token" /> */}
                            </div>
                        </div>
                    ))}
                    {mirrorNFTs.map((id) => (
                        <div key={id} className="token-container">
                            <div className="claim-gif">
                                <img src="NFTairdrop.png" loading="lazy" alt="" />
                            </div>
                            <p className="token-id">NFT {id}</p>
                            {/* <button type='button' onClick={() => unstakeNFT(id)}>Unstake</button> */}
                            <button type='button' className='redbutton' onClick={() => openModalWithId(id)}>Unstake</button>
                            <div className='rewards-container'>
                                <span className='reward-id'>{rewards[String(id)]}</span>
                                <img className='reward-icon' src="/favicon2.png" alt="zero token" />
                            </div>
                        </div>
                    ))}
                    <ReactModal
                        isOpen={modalOpen}
                        onRequestClose={() => setModalOpen(false)}
                        contentLabel="Stake NFT Airdrop Modal"
                        className="react-modal-content"
                        style={{
                        overlay: {
                            backgroundColor: 'rgba(28, 28, 28, 0.7)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                        content: {
                            position: 'relative',
                            top: 'auto',
                            left: 'auto',
                            right: 'auto',
                            bottom: 'auto',
                            inset: 'auto',
                        },
                        }}
                    >

                        <div className='modal-content'>
                        <div className="content">
                            <img src="/warning.png" className='warning-icon' alt="" />
                            <p className="center-text h2">Unstake NFT Airdrop {unstakingId}:</p>
                        </div>
                        
                        <p className='p'>If you UNSTAKE the NFT Airdrop it will burn and it will disappear!</p>
                        <p className='p'>Are you sure you want to Unstake the NFT Airdrop with ID {unstakingId}? </p>
                        <button className='confirmBUtton' type='button' onClick={() => { 
                            if (unstakingId !== null) {
                                unstakeNFT(unstakingId); 
                                setModalOpen(false); 
                            }
                        }}>
                            Confirm
                        </button>
                        <button className='cancelBUtton' type='button' onClick={() => setModalOpen(false)}>
                            Cancel
                        </button>
                        </div>

                    </ReactModal>
                </div>
            </div>
            </>
            ) : (
                <div className='contenedorCentral'>
                    <div className='card'>
                        <img src="NFTairdrop.png" className='nftAirdrop' alt="" />

                        <div className='card-text'>To have access to NFT Staking you need to have at least 1 NFT (ZERO Airdrop) and be connected to MetaMask.</div>
                        <a 
                        target='_blank'
                        rel='noreferrer'
                        href="https://discord.gg/kMW47sWrc9">
                        <button type='button' className='card-button selectorNFT3'>GET NFT</button>
                        </a>
                    </div>
                </div>
               
            )}
        </div>
    );
};

export default StakingPage;
