import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import QuestionHelper from 'components/QuestionHelper';
import useI18n from 'hooks/useI18n'
import Web3 from 'web3';
import SwapFeeRewardAbi from './SwapFeeRewardAbi.json';
import './styles.css';
import NFTCollectionAbi from './nftABI.json';

const NFTCollection1Address = '0x5631B7960972e34e85DFAE7B372CeaBe61b0aA38'; // MGenesis

const SwapFeeRewardContractAddress = '0xFD85CCDb689dcFA83f2041b21C0f42854170e197'; // Replace with your real address

let web3; // Declaración de la variable
if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' });
  web3 = new Web3((window as any).ethereum);
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.');
  web3 = new Web3(); // Esto creará una instancia de Web3 sin un proveedor específico.
}

const SwapFeeRewardContract = new web3.eth.Contract(SwapFeeRewardAbi, SwapFeeRewardContractAddress);
const NFTCollection1Contract = new web3.eth.Contract(NFTCollectionAbi, NFTCollection1Address);

const SwapFeeRewardDApp = () => {
  const [fees, setFees] = useState<string>("");
  const [account, setAccount] = useState<null | string>(null);
  const [ownsNFT, setOwnsNFT] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(false);
  const provider = useRef(null as any);
  const [minWithdrawal, setMinWithdrawal] = useState(0);
  const TranslateString = useI18n()

  const checkNFTsOwnership = useCallback(async (accountToCheck: string) => {
    if(accountToCheck) {
      try {
        const balance1 = await NFTCollection1Contract.methods.balanceOf(accountToCheck).call();
        if (balance1 > 0) {
          setOwnsNFT(true);
        } else {
          setOwnsNFT(false);
        }
      } catch (error) {
        console.error('Error checking NFT ownership: ', error);
      }
    }
  }, []);

  useEffect(() => {
    if(account) {
      checkNFTsOwnership(account);
    }
  }, [checkNFTsOwnership, account]);
  
  

  const loadWeb3 = useCallback(async () => {
    if (window.ethereum) {
      provider.current = window.ethereum;
      window.web3 = new Web3(provider.current);
      await provider.current.request({ method: 'eth_requestAccounts' });

      // Adding listener for Metamask account change
      provider.current.on('accountsChanged', async function (accounts: string[]) {
        setAccount(accounts[0]);
        checkNFTsOwnership(accounts[0]);
      });
    } else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  }, [checkNFTsOwnership]);

  const loadAccounts = useCallback(async () => {
    const accounts = await provider.current.request({ method: 'eth_accounts' });
    setAccount(accounts[0]);
  }, []);

  const loadBlockchainData = useCallback(async () => {
    if(account) {
      try {
        setLoading(true);
        const newFees = await SwapFeeRewardContract.methods.getFeesInZERO().call({ from: account });
        const minWithdrawalAmountWei = await SwapFeeRewardContract.methods.minWithdrawalAmount().call();
        setMinWithdrawal(web3.utils.fromWei(minWithdrawalAmountWei, 'ether'));
        setFees(parseFloat(web3.utils.fromWei(newFees, 'ether')).toFixed(8));
        setLoading(false);
      } catch (error) {
        console.error('Error loading blockchain data: ', error);
        setLoading(false);
      }
    }
  }, [account]);

  useEffect(() => {
    loadBlockchainData();
  }, [loadBlockchainData]);

  const withdraw = async () => {
    try {
      setLoading(true);
      await SwapFeeRewardContract.methods.withdraw().send({ from: account });
      setLoading(false);
      // Reload fees after successful withdrawal
      loadBlockchainData();
    } catch (error) {
      console.error('Error withdrawing: ', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadWeb3();
    loadAccounts();
  }, [loadWeb3, loadAccounts]);
  

  return (
    <div className='containerPrincipalx'>
      <h2>Fee Reward
        <QuestionHelper
          text={TranslateString(
            300,
            'Fee Reward is our ZERO FEES module, meaning that by owning a Staked NFT Genesis, DEX will return 100% of your fees in the form of ZERO tokens.'
          )}
        />
      </h2>
      {loading || ownsNFT === null || account === null ? (
        <div>
        <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
      </div>
      ) : ownsNFT && account ? (
        <>
          <div className='fees'>
            <span className='reward-id'>{fees}</span>
            <img className='reward-icon' src="/favicon2.png" alt="zero token" />
          </div>
          <button className='feesbutton' type='button' disabled={parseFloat(fees) < minWithdrawal} onClick={withdraw}>Withdraw</button>
          <p>(Min. {minWithdrawal} ZERO to withdraw.)</p>
        </>
      ) : (
        <div className='card'>
          <video autoPlay loop muted className='card-gif'>
            <source src='NFTgenesis.mp4' type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <div className='card-text'>To have access to Fee Reward you need to have at least 1 NFT (Genesis or Standard) in staking and be connected to MetaMask.</div>
          <Link to="/genesis_staking">
            <button type='button' className='card-button selectorNFT3'>STAKE NFT</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default SwapFeeRewardDApp;
