import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import { LangType } from '@pancakeswap-libs/uikit'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import AddLiquidity from './AddLiquidity'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import BuyNFTs from './BuyNFTs/BuyNFTs'
import Buynftsoneofus from './BuyNFTs/BuyNFTsOneofus'
import Buynftsoneofusart from './BuyNFTs/BuyNFTsOneofusArt'
import Buynftstanglebulls from './BuyNFTs/BuyNFTsTangleBulls'
import Buynftstintan from './BuyNFTs/BuyNFTsTinTan'
import GenesisClaim from './ClaimNFT/genesis';
import StandardClaim from './ClaimNFT/standard';
import Claim from './ClaimNFT/defaultclaim';
import GenesisStaking from './StakingNFT/genesis';
import StandardStaking from './StakingNFT/standard';
import AirdropStaking from './StakingNFT/airdrop';
import SwapFeeReward from './FeeReward/swapfeereward';
import StakingTokens from './StakingToken/stakingToken';
import FactoryTokens from './FactoryTokens/index';
import CreateSale from './Launchpad/createSale';
import ViewSales from './Launchpad/viewSales';
import SaleDetails from './Launchpad/sale';
import EditSale from './Launchpad/editSale';
import MyNFTs from './NFTMarketplace/myNFTs';
import MyNFTsOnly from './NFTMarketplace/myNFTsOnly';
import MyNFTsOnly2 from './NFTMarketplace/myNFTsOnly2';
import Marketplace from './NFTMarketplace/Marketplace'
import MarketplaceGenesis from './NFTMarketplace/MarketplaceGenesis'
import MarketplaceAirdrop from './NFTMarketplace/MarketplaceAirdrop'
import MarketplaceTanglebulls from './NFTMarketplace/MarketplaceTanglebulls'
import MarketplaceOrigen from './NFTMarketplace/MarketplaceOrigen'
import MarketplaceOneofus from './NFTMarketplace/MarketplaceOneofus'
import MarketplaceOneofusArt from './NFTMarketplace/MarketplaceOneofusArt'
import MarketplaceTintan from './NFTMarketplace/MarketplaceTinTan'
import NFTTransactions from './NFTMarketplace/NFTTransactions'
import Lottery from './Lottery/lottery'
import { RedirectPathToSwapOnly } from './Swap/redirects'
import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'

import Menu from '../components/Menu'
import useGetDocumentTitlePrice from '../hooks/useGetDocumentTitlePrice'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
/*     background-image: url('/images/arch-dark.svg');
    background-repeat: no-repeat;
    background-position: center 420px, 10% 230px, 90% 230px;
    background-size: contain, 266px, 266px;
    */
    min-height: 90vh; 
  }
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const CACHE_KEY = 'pancakeSwapLanguage'

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  const fileId = 6

  const credentials: Credentials = {
    token: apiKey,
  }

  const stringTranslationsApi = new StringTranslations(credentials)

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem(CACHE_KEY)
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  const fetchTranslationsForSelectedLanguage = async () => {
    // TODO Como eliminamos los idiomas, eliminamos esta función que estaba dandonos error en la consola. Ahora se ejecuta pero no hace nada.
/*     stringTranslationsApi
      .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
      .then((translationApiResponse) => {
        if (translationApiResponse.data.length < 1) {
          setTranslations(['error'])
        } else {
          setTranslations(translationApiResponse.data)
        }
      })
      .then(() => setTranslatedLanguage(selectedLanguage))
      .catch((error) => {
        setTranslations(['error'])
        console.error(error)
      }) */
  }

  useEffect(() => {
    if (selectedLanguage) {
      fetchTranslationsForSelectedLanguage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage])

  const handleLanguageSelect = (langObject: LangType) => {
    setSelectedLanguage(langObject)
    localStorage.setItem(CACHE_KEY, langObject.code)
  }

  useGetDocumentTitlePrice()

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage: handleLanguageSelect, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Menu>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>
                      <Route exact strict path="/swap" component={Swap} />
                      <Route exact strict path="/find" component={PoolFinder} />
                      <Route exact strict path="/pool" component={Pool} />
                      <Route exact strict path="/buy_nfts" component={BuyNFTs} />
                      <Route exact strict path="/buynftsoneofus" component={Buynftsoneofus} />
                      <Route exact strict path="/buynftsoneofusart" component={Buynftsoneofusart} />
                      <Route exact strict path="/buynftstanglebulls" component={Buynftstanglebulls} />
                      <Route exact strict path="/buynftstintan" component={Buynftstintan} />
                      <Route exact strict path="/token_staking" component={StakingTokens} />
                      <Route exact strict path="/swapfeereward" component={SwapFeeReward} />
                      <Route exact strict path="/airdrop_staking" component={AirdropStaking} />
                      <Route exact strict path="/genesis_staking" component={GenesisStaking} />
                      <Route exact strict path="/standard_staking" component={StandardStaking} />
                      <Route exact strict path="/claim" component={Claim} />
                      <Route exact strict path="/genesis_claim" component={GenesisClaim} />
                      <Route exact strict path="/standard_claim" component={StandardClaim} />
                      <Route exact strict path="/factory_tokens" component={FactoryTokens} />
                      <Route path="/create_sale/:tokenAddress?" component={CreateSale} />
                      <Route exact strict path="/view_sales" component={ViewSales} />
                      <Route path="/sale/:saleAddress" component={SaleDetails} />
                      <Route path="/edit_sale/:saleAddress" component={EditSale} />
                      <Route exact strict path="/my_nfts" component={MyNFTs} />
                      <Route exact strict path="/my_nfts_only" component={MyNFTsOnly} />
                      <Route exact strict path="/my_nfts_only2" component={MyNFTsOnly2} />
                      <Route exact strict path="/marketplace" component={Marketplace} />
                      <Route exact strict path="/marketplaceGenesis" component={MarketplaceGenesis} />
                      <Route exact strict path="/marketplaceAirdrop" component={MarketplaceAirdrop} />
                      <Route exact strict path="/marketplaceTanglebulls" component={MarketplaceTanglebulls} />
                      <Route exact strict path="/marketplaceOrigen" component={MarketplaceOrigen} />
                      <Route exact strict path="/marketplaceOneofus" component={MarketplaceOneofus} />
                      <Route exact strict path="/marketplaceOneofusArt" component={MarketplaceOneofusArt} />
                      <Route exact strict path="/marketplaceTintan" component={MarketplaceTintan} />
                      <Route exact strict path="/nft_transactions" component={NFTTransactions} />
                      <Route exact strict path="/lottery" component={Lottery} />
                      <Route exact path="/add" component={AddLiquidity} />
                      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

                      {/* Redirection: These old routes are still used in the code base */}
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />

                      <Route component={RedirectPathToSwapOnly} />
                    </Switch>
                  </Web3ReactManager>
                  <Marginer />
                </BodyWrapper>
              </Menu>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
