import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom';
import Web3 from 'web3'
import NFTMarketplaceAbi from './NFTMarketplaceAbi.json'
import ERC721Abi from './ERC721Abi.json'
import './NFTMarketplace.css'

let web3
if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' })
  web3 = new Web3((window as any).ethereum)
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.')
  web3 = new Web3()
}

interface EthereumProvider {
  on: (event: string, callback: (accounts: string[]) => void) => void;
  removeListener: (event: string, callback: (accounts: string[]) => void) => void;
  // cualquier otro método o propiedad que necesites usar
}


const marketplaceAddresses = [
  '0xC54EF33CfA81c4A3D62a986816C1042660f2C7F5'
]

const collections = [
  { address: '0x5bB65440933860053F6190580BB45800124af217' }
]

async function fetchTokenMetadata(tokenContract, tokenId) {
  try {
    // Obtener la URL del metadato usando tokenURI
    const tokenURI = await tokenContract.methods.tokenURI(tokenId).call();
    
    // Hacer una solicitud HTTP para obtener el JSON del metadato
    const response = await fetch(tokenURI);
    const metadata = await response.json();
    
    // Devolver la URL de la imagen del metadato
    return metadata.image;
  } catch (error) {
    console.error('Error fetching token metadata:', error);
    return null;
  }
}

function NFTMarketplaceDApp() {
  const [account, setAccount] = useState('')
  const [tokensForSale, setTokensForSale] = useState<any[]>([]); // Change the type to any[]
  const [currentPage, setCurrentPage] = useState(1); // Estado para controlar la página actual
  const itemsPerPage = 8; // Número de elementos por página
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc'); // 'desc' para descendente y 'asc' para ascendente

  const loadAccounts = useCallback(() => {
    return new Promise((resolve, reject) => {
      (window.ethereum as any).request({ method: 'eth_requestAccounts' })
      .then((accounts: any[]) => {
        console.log('Cuentas cargadas: ', accounts)
        setAccount(accounts[0])
        resolve(accounts[0])
      })
      .catch((error: any) => {
        console.error('Error loading accounts: ', error)
        reject(error)
      })
    })
  }, [])

  const loadTokensForSale = useCallback(async () => {
    try {
      setLoading(true);
      const allTokensForSale: any[] = [];
  
      await Promise.all(
        marketplaceAddresses.map(async (address) => {
          console.log('Checking contract at address: ', address)
          const marketplaceContract = new web3.eth.Contract(NFTMarketplaceAbi, address)
          const collectionAddress = await marketplaceContract.methods.nftCollection().call()
          console.log('Collection address for this contract: ', collectionAddress)

          const tokenContract = new web3.eth.Contract(ERC721Abi, collectionAddress);
  
          const tokens = await marketplaceContract.methods.getTokensForSale().call()
          console.log('All tokens for sale: ', tokens)
  
          const tokenDetails = await Promise.all(
            tokens.map(async (tokenId) => {
              const sale = await marketplaceContract.methods.sales(tokenId).call()
              const image = await fetchTokenMetadata(tokenContract, tokenId); // Aquí llamamos a fetchTokenMetadata
              return {
                contract: address,
                collection: collectionAddress,
                tokenId,
                price: web3.utils.fromWei(sale.price, 'ether'),
                seller: sale.seller,
                image
              }
            })
          )
          allTokensForSale.push(...tokenDetails);
        })
      )
      
      const userTokensForSale = allTokensForSale.filter(token => token.seller.toLowerCase() !== account.toLowerCase());

      // Ordenar los tokens por precio de menor a mayor
      userTokensForSale.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

      allTokensForSale.sort((a, b) => sortOrder === 'asc' ? parseFloat(a.price) - parseFloat(b.price) : parseFloat(b.price) - parseFloat(a.price));

      setTokensForSale(allTokensForSale); // TODOS INCLUIDOS LOS DEL USUARIO

      console.log('Final state of tokens for sale: ', userTokensForSale)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error loading tokens for sale: ', error)
    }
  }, [account, sortOrder])

  const handleSortOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSortOrder(e.target.value);
  };
  
  const buyToken = async (contractAddress: string, tokenId: string, price: string) => {
    try {
      setLoading(true);
      const marketplaceContract = new web3.eth.Contract(NFTMarketplaceAbi, contractAddress)
      await marketplaceContract.methods.buy(tokenId).send({ from: account, value: web3.utils.toWei(price, 'ether') })

      // remove the bought token from the state
      const updatedTokensForSale = tokensForSale.filter(
        (token) => !(token.contract === contractAddress && token.tokenId === tokenId)
      )
      setTokensForSale(updatedTokensForSale)
      setLoading(false);
      console.log(`Bought token with id ${tokenId} for ${price} ether`)
    } catch (error) {
      setLoading(false);
      console.error('Error buying token: ', error)
    }
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        await loadAccounts()
        .then(() => {
          loadTokensForSale();
        })
      } catch (error) {
        console.error('Error initializing app: ', error)
      }
    };
  
    initialize();
  }, [loadAccounts, loadTokensForSale]);

  useEffect(() => {
    const handleAccountChange = () => {
      loadAccounts().then(loadTokensForSale)
    }
  
    // Comprobar si window.ethereum está definido
    if (window.ethereum) {
      (window.ethereum as EthereumProvider).on('accountsChanged', handleAccountChange);
    }
  
    // Limpiando el evento cuando el componente se desmonta
    return () => {
      if (window.ethereum) {
        (window.ethereum as EthereumProvider).removeListener('accountsChanged', handleAccountChange);
      }
    };
  }, [loadAccounts,loadTokensForSale])

  // Función para controlar el cambio de página
  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastToken = currentPage * itemsPerPage;
  const indexOfFirstToken = indexOfLastToken - itemsPerPage;
  const currentTokensForSale = tokensForSale.slice(indexOfFirstToken, indexOfLastToken);

  // Crear botones de página según el número de elementos por página
  const pageNumbers: number[] = [];
  for (let i = 1; i <= Math.ceil(tokensForSale.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }  

  return (
    <div className="container-principal">
      <h2><Link type='button' to="/marketplace" className='selectorNFT4'>Marketplace</Link> <Link type='button' to="/my_nfts" className='selectorNFT5'>My NFTs</Link><Link to="/nft_transactions" className='icontransaction'><img src="images/tx.png" alt="" /></Link></h2>

      {/* Encabezado para la selección de orden */}
      <div style={{marginBottom: '10px', color: 'white'}}>
        <strong>Sort by price:</strong>
      </div>

      {/* Radio buttons para seleccionar el orden de clasificación */}
      <div style={{alignItems: 'center', color: 'white', marginBottom: '20px'}}>
        <label htmlFor="desc" style={{marginRight: '20px'}}>
          <input id="desc" type="radio" value="desc" checked={sortOrder === 'desc'} onChange={handleSortOrderChange} />
          Descending
        </label>
        <label htmlFor="asc">
          <input id="asc" type="radio" value="asc" checked={sortOrder === 'asc'} onChange={handleSortOrderChange} />
          Ascending
        </label>
      </div>
      {loading || account === '' ? (
        <div>
        <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
      </div>
      ) : (
        <>
          <div className="nft-container">
            {currentTokensForSale.map((token) => (
              <div className="nft-card" key={`${token.contract}-${token.tokenId}`}>
                <div className="video-container">  
                  <img alt={`Token ${token.tokenId}`} className="claim-gif" loading="lazy" 
                      src={token.image}
                  />
                  <div className="nft-id">Token Id: {token.tokenId}</div>
                </div>
                <input
                      className='disabledinput'
                      value={`${token.price} SMR`}
                      disabled
                    />
                {token.seller.toLowerCase() === account.toLowerCase() ? (
                  <>
                    <button type="button" className='greydisabled' disabled>
                      This is your NFT
                    </button>
                  </>
                ) : (
                  <button className="buy-button" type="button" onClick={() => buyToken(token.contract, token.tokenId, token.price)}>
                    Buy
                  </button>
                )}

              </div>
            ))}
          </div>
          <div className="pagination">
          {pageNumbers.map(number => {
            return (
              currentPage === number ? (
                <span className="activePage">{number}</span>
              ) : (
                <button 
                  className='pagination-btn'
                  type='button'
                  key={number}
                  onClick={() => handlePageChange(number)}
                >
                  {number}
                </button>
              )
            );
          })}

        </div>
        </>
      )}

    </div>
  )
  
}

export default NFTMarketplaceDApp
