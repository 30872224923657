import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import QuestionHelper from 'components/QuestionHelper';
import useI18n from 'hooks/useI18n'
import { useForm, Resolver } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import LaunchpadFactoryAbi from './LaunchpadFactoryAbi.json';
import ERC20Abi from './ERC20Abi.json';
import './createSale.css';


const launchpadFactoryAddress = "0xdbdB5abF89F539Fc6e758D00a464a47277221f0F";
let web3;

if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' });
  web3 = new Web3((window as any).ethereum);
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.');
  web3 = new Web3(); 
}

const LaunchpadFactoryContract = new web3.eth.Contract(LaunchpadFactoryAbi, launchpadFactoryAddress);

const validationSchema = yup.object({
  seller: yup.string().required('Required'),
  token: yup.string().required('Required'),
  softcap: yup.number().required('Required'),
  hardcap: yup.number().required('Required'),
  startTime: yup.string().required('Required'),
  endTime: yup.string().required('Required'),
  liquidityPercent: yup.number().required('Required'),
  limitPerAccountEnabled: yup.boolean().required('Required'),
  limitPerAccount: yup.number().required('Required'),
  liquidityUnlockTime: yup.number().required('Required'),
  tokensPerEth: yup.number().required('Required'),
  tokensPerEth2: yup.number().required('Required'),
  tokensPerEthListing: yup.number().required('Required'),
  vestingEnabled: yup.boolean().required('Required'),
  vestingInterval: yup.number().required('Required'),
  vestingDuration: yup.number().required('Required'),
});

interface RouteParams {
  tokenAddress: string;
}

const CreateSale = () => {
  const [account, setAccount] = useState('');
  const [loading, setLoading] = useState(false);
  const [saleAddress, setSaleAddress] = useState(null);
  const [error, setError] = useState<Error | null>(null);
  const [tokenSupply, setTokenSupply] = useState(null);
  const [tokensToSend, setTokensToSend] = useState<number | null>(null);
  const { tokenAddress } = useParams<RouteParams>();
  const [tokenAdress, setTokenAdress] = useState<string | null>(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [softcap, setSoftcap] = useState<number | null>(null);
  const [hardcap, setHardcap] = useState<number | null>(null);
  const [tokensPerEth, setTokensPerEth] = useState<number | null>(null);
  const [tokensPerEth2, setTokensPerEth2] = useState<number | null>(null);
  const [tokensPerEthListing, setTokensPerEthListing] = useState<number | null>(null);
  const [liquidityPercent, setLiquidityPercent] = useState<number | null>(null);
  const [percent, setPercent] = useState<number | null>(null);
  const [feeAmount, setFeeAmount] = useState(null);
  const [feeToken, setFeeToken] = useState(null);
  const TranslateString = useI18n()

  type FormFields = {
    seller: string,
    token: string,
    softcap: number,
    hardcap: number,
    startTime: string,
    endTime: string,
    liquidityPercent: number,
    limitPerAccountEnabled: boolean,
    limitPerAccount: number | null,
    liquidityUnlockTime: number,
    tokensPerEth: number,
    tokensPerEth2: number,
    tokensPerEthListing: number,
    vestingEnabled: boolean,
    vestingInterval: number,
    vestingDuration: number,
  };

  const { register, handleSubmit, formState:{ errors }, setValue } = useForm<FormFields>({
    resolver: yupResolver(validationSchema) as Resolver<FormFields, Record<string, unknown>>,
    defaultValues: {
      limitPerAccount: 0,
      vestingInterval: 0,
      vestingDuration: 0,
      liquidityUnlockTime: 1000
    },
  });
  
  useEffect(() => {
    setValue('seller', account);
  }, [account, setValue]);

  const onSubmit = async (data) => {

    setLoading(true);
    setError(null);

    if(percent && percent > 100){
      console.error('The tokens to be sent for sale exceed the supply.');
      setError(new Error("The tokens to be sent for sale exceed the supply."));
      setLoading(false);
      return;
    }

/*     if(percent && percent < 1){
      console.error('The percentage of tokens must be greater than 1% of the total supply.');
      setError(new Error("The percentage of tokens must be greater than 1% of the total supply."));
      setLoading(false);
      return;
    } */

    const feeTokenContract2 = new web3.eth.Contract(ERC20Abi, feeToken);
    const feeTokenBalance = await feeTokenContract2.methods.balanceOf(account).call();
    const feeTokenBalanceInEther = web3.utils.fromWei(feeTokenBalance, "ether");

    if (feeAmount !== null && feeTokenBalanceInEther && parseFloat(feeTokenBalanceInEther) < (feeAmount || 0)) {
      setError(new Error("Insufficient token ZERO balance to cover the fee."));
      setLoading(false);
      return;
    }

    const tokenContract2 = new web3.eth.Contract(ERC20Abi, data.token);
    const tokenBalance2 = await tokenContract2.methods.balanceOf(account).call();
    const tokenBalanceInEther = web3.utils.fromWei(tokenBalance2, "ether");

    if (parseFloat(tokenBalanceInEther) < ((data.tokensPerEth * data.softcap) + (data.tokensPerEth2 * (data.hardcap - data.softcap)))) {
      setError(new Error("Insufficient token balance."));
      setLoading(false);
      return;
    }

    try {
      const tokenContract = new web3.eth.Contract(ERC20Abi, data.token);
      await tokenContract.methods.totalSupply().call();
      await tokenContract.methods.balanceOf(account).call();
    } catch (err) {
      console.log('(Token) The provided address is not a valid ERC20 token');
      setError(new Error('(Token) The provided address is not a valid ERC20 token'));
      setLoading(false);
      return;
    }
    if(data.softcap>=data.hardcap){
      console.log('(Softcap) The softcap must be smaller than the hardcap.');
      setError(new Error('(Softcap) The softcap must be smaller than the hardcap.'));
      setLoading(false);
      return;
    }
    if (data.softcap && data.softcap !== ''){
      const newSoftcap = new BigNumber(data.softcap);
      data.softcap = web3.utils.toWei(newSoftcap.toString(), "ether");
    }
    if (data.hardcap && data.hardcap !== ''){
      const newHardcap = new BigNumber(data.hardcap);
      data.hardcap = web3.utils.toWei(newHardcap.toString(), "ether");
    }
    if(data.tokensPerEth <= data.tokensPerEthListing){
      console.log('(Tokens per 1 SMR) "Tokens per 1 SMR Listing" must be less than "Tokens per 1 SMR".');
      setError(new Error('(Tokens per 1 SMR) "Tokens per 1 SMR Listing" must be less than "Tokens per 1 SMR".'));
      setLoading(false);
      return;
    }
    if(data.tokensPerEth2 <= data.tokensPerEthListing){
      console.log('(Tokens per 1 SMR) "Tokens per 1 SMR Listing" must be less than "Tokens per 1 SMR".');
      setError(new Error('(Tokens per 1 SMR) "Tokens per 1 SMR Listing" must be less than "Tokens per 1 SMR".'));
      setLoading(false);
      return;
    }
    if(data.tokensPerEth2 > data.tokensPerEth){
      console.log('(Tokens per 1 SMR) Tokens per 1 SMR Hardcap must be less than Tokens per 1 SMR Softcap.');
      setError(new Error('(Tokens per 1 SMR) Tokens per 1 SMR Hardcap must be less than Tokens per 1 SMR Softcap.'));
      setLoading(false);
      return;
    }



/*     if(data.tokensPerEth && data.tokensPerEth !== ''){
      const tokenPerEther = new BigNumber('1').dividedBy(data.tokensPerEth);
      data.tokensPerEth = web3.utils.toWei(tokenPerEther.toFixed(18), "ether");
    }
    if(data.tokensPerEth2 && data.tokensPerEth2 !== ''){
      const tokenPerEther2 = new BigNumber('1').dividedBy(data.tokensPerEth2);
      data.tokensPerEth2 = web3.utils.toWei(tokenPerEther2.toFixed(18), "ether");
    }
    if(data.tokensPerEthListing && data.tokensPerEthListing !== ''){
      const tokenPerEtherListing = new BigNumber('1').dividedBy(data.tokensPerEthListing);
      data.tokensPerEthListing = web3.utils.toWei(tokenPerEtherListing.toFixed(18), "ether");
    }     */

    if(data.tokensPerEth && data.tokensPerEth !== ''){
      const tokenPerEther = new BigNumber(data.tokensPerEth);
      data.tokensPerEth = web3.utils.toWei(tokenPerEther.toFixed(18), "ether");
    }
    if(data.tokensPerEth2 && data.tokensPerEth2 !== ''){
      const tokenPerEther2 = new BigNumber(data.tokensPerEth2);
      data.tokensPerEth2 = web3.utils.toWei(tokenPerEther2.toFixed(18), "ether");
    }
    if(data.tokensPerEthListing && data.tokensPerEthListing !== ''){
      const tokenPerEtherListing = new BigNumber(data.tokensPerEthListing);
      data.tokensPerEthListing = web3.utils.toWei(tokenPerEtherListing.toFixed(18), "ether");
    }
    


    if(data.liquidityPercent < 50 && data.liquidityPercent > 100){
      console.log('(Liquidity Percent %) The liquidity percentage for the listing must be between 50% - 100%.');
      setError(new Error('(Liquidity Percent %) The liquidity percentage for the listing must be between 50% - 100%.'));
      setLoading(false);
      return;
    }
    if(data.limitPerAccount !== ''){
      const limitPerAccount2 = new BigNumber(data.limitPerAccount);
      data.limitPerAccount = web3.utils.toWei(limitPerAccount2.toString(), "ether");
    }
    if(data.liquidityUnlockTime < 1){
      console.log('(Liquidity Unlock Time) The liquidity Unlock Time must be greater than 1 day.');
      setError(new Error('(Liquidity Unlock Time) The liquidity Unlock Time must be greater than 1 day.'));
      setLoading(false);
      return;
    }
    if(data.limitPerAccountEnabled && data.limitPerAccount <= 0){
      console.log('(Limit Per Account (SMR)) The Limit Per Account (SMR) must be greater than 0.');
      setError(new Error('(Limit Per Account (SMR)) The Limit Per Account (SMR) must be greater than 0.'));
      setLoading(false);
      return;
    }
    if(data.vestingEnabled && (data.vestingDuration <= 0 || data.vestingInterval <= 0 || data.vestingDuration % data.vestingInterval !== 0 || data.vestingDuration / data.vestingInterval === 0)){
      console.log('Vesting Duration must be greater than Vesting Interval and Vesting Duration must be mutiple of Vesting Interval. Example: Interval 10, Duration 100.');
      setError(new Error('Vesting Duration must be greater than Vesting Interval and Vesting Duration must be mutiple of Vesting Interval. Example: Interval 10, Duration 100.'));
      setLoading(false);
      return;
    }
    if(data.liquidityUnlockTime && data.liquidityUnlockTime !== '')
      data.liquidityUnlockTime = data.liquidityUnlockTime * 24 * 60 * 60; // Pasar días a segundos.

    if(data.vestingInterval && data.vestingInterval !== '')
      data.vestingInterval = data.vestingInterval * 24 * 60 * 60; // Pasar días a segundos.

    if(data.vestingDuration && data.vestingDuration !== '')
      data.vestingDuration = data.vestingDuration * 24 * 60 * 60; // Pasar días a segundos.

    if(data.startTime && data.startTime !== '')
      data.startTime = Math.floor(new Date(data.startTime).getTime() / 1000);

    if(data.endTime && data.endTime !== '')
      data.endTime = Math.floor(new Date(data.endTime).getTime() / 1000);

    if(data.endTime <= data.startTime){
      console.error('End time must be greater than start time.');
      setError(new Error("End time must be greater than start time."));
      setLoading(false);
      return;
    }
  
    setLoading(true);
    try {
      const params = {
        seller: data.seller,
        token: data.token,
        softcap: data.softcap,
        hardcap: data.hardcap,
        startTime: data.startTime,
        endTime: data.endTime,
        liquidityPercent: data.liquidityPercent,
        tokensPerEth: data.tokensPerEth,
        tokensPerEth2: data.tokensPerEth2,
        tokensPerEthListing: data.tokensPerEthListing,
        limitPerAccountEnabled: data.limitPerAccountEnabled,
        limitPerAccount: data.limitPerAccount,
        liquidityUnlockTime: data.liquidityUnlockTime,
        vestingEnabled: data.vestingEnabled,
        vestingInterval: data.vestingInterval,
        vestingDuration: data.vestingDuration
      };

      console.log(params)

      const maxTokenSupply = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF'; // El máximo en hexadecimal
      const tokenContract = new web3.eth.Contract(ERC20Abi, data.token); // asegúrate de tener la ABI del token ERC-20
      await tokenContract.methods.approve(launchpadFactoryAddress, maxTokenSupply).send({ from: account });
      const feeTokenContract = new web3.eth.Contract(ERC20Abi, feeToken);
      await feeTokenContract.methods.approve(launchpadFactoryAddress, maxTokenSupply).send({ from: account });      
      const createSaleTx = await LaunchpadFactoryContract.methods.createSale(params).send({ from: account });
  
      console.log(createSaleTx);
      setSaleAddress(createSaleTx.events.SaleCreated.returnValues.newSale);
      setLoading(false);
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
        setError(err);
      } else {
        console.log(err);
        setError(new Error("An unknown error occurred."));
      }
      setLoading(false);
    }
  };

  // Reemplaza cualquier carácter que no sea un dígito por nada
  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
  };

  const handleTokenChange = async (valor) => {setTokenAdress(valor)}
  const handleSoftCapChange = (valor) => {setSoftcap(valor)}
  const handleHardCapChange = (valor) => {setHardcap(valor)}
  const handleTokensPerEthChange = (valor) => {setTokensPerEth(valor)}
  const handleTokensPerEth2Change = (valor) => {setTokensPerEth2(valor)}
  const handleTokensPerEthListingChange = (valor) => {setTokensPerEthListing(valor)}
  const handleLiquidityPercentChange = (valor) => {setLiquidityPercent(valor)}

  useEffect(() => {
    const fetchTotalSupply = async () => {
      if (tokenAdress && tokenAdress.length === 42 && web3.utils.isAddress(tokenAdress)) {
        const code = await web3.eth.getCode(tokenAdress);
        if (code === '0x') {
          console.error('The address is not a valid ERC20 contract.');
          setError(new Error("The address is not a valid ERC20 contract."));
          return;
        }
        const tokenContract = new web3.eth.Contract(ERC20Abi, tokenAdress);
        let totalSupply = await tokenContract.methods.totalSupply().call();
        const symbol = await tokenContract.methods.symbol().call();
        setTokenSymbol(symbol);
        totalSupply = web3.utils.fromWei(totalSupply, "ether");
        setTokenSupply(totalSupply);
      }else{
        setTokenSupply(null);
        setTokensToSend(null);
      }
    };
    fetchTotalSupply();
  }, [tokenAdress]);

  useEffect(() => {
    const fetchSendTokens = async () => {
      if(softcap && hardcap && tokensPerEth && tokensPerEth2 && tokensPerEthListing && liquidityPercent){
        const amount1 = softcap * tokensPerEth;
        const amount2 = (hardcap - softcap) * tokensPerEth2;
        const amount3 = hardcap * tokensPerEthListing * Number(liquidityPercent) / 100;
        const sumaAmount = amount1 + amount2 + amount3;
        setTokensToSend(sumaAmount);
      }else{
        setTokensToSend(null);
      }
    };
    fetchSendTokens();
  }, [softcap, hardcap, tokensPerEth, tokensPerEth2, tokensPerEthListing, liquidityPercent]);

  useEffect(() => {
    const fetchSPercent = async () => {
      if(tokensToSend && tokenSupply){
        const percent2 = Number(tokensToSend)/Number(tokenSupply)*100;
        setPercent(percent2);
        if(percent2 > 100){
          console.error('The tokens to be sent for sale exceed the supply.');
          setError(new Error("The tokens to be sent for sale exceed the supply."));
        }
      }else{
        setPercent(null);
      }
    };
    fetchSPercent();
  }, [tokensToSend, tokenSupply]);

  useEffect(() => {
    const loadAccounts = async () => {
      const accounts = await (window as any).ethereum.request({ method: 'eth_accounts' });
      setAccount(accounts[0]);
    }
    const handleAccountsChanged = (accounts: string[]) => {
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
    };
    if ((window as any).ethereum) {
      (window as any).ethereum.on('accountsChanged', handleAccountsChanged);
    }
    loadAccounts();
  }, []);

  useEffect(() => {
    if (tokenAddress) {
      setTokenAdress(tokenAddress);
    }
  }, [tokenAddress]);

  useEffect(() => {
    const fetchFeeAmount = async () => {
      try {
        const feeAmountFromContract = await LaunchpadFactoryContract.methods.getFeeAmount().call();
        setFeeAmount(web3.utils.fromWei(feeAmountFromContract, "ether"));
      } catch (fetchFeeAmountError) {
        console.error("Failed to fetch fee amount: ", fetchFeeAmountError);
      }
    };

    const fetchFeeToken = async () => {
      try {
        const feeTokenFromContract = await LaunchpadFactoryContract.methods.getFeeToken().call();
        setFeeToken(feeTokenFromContract);
      } catch (fetchFeeTokenError) {
        console.error("Failed to fetch fee token: ", fetchFeeTokenError);
      }
    };

    fetchFeeAmount();
    fetchFeeToken();
  }, []);
  

  return (
<div className='formulario1'>
      <h2><Link type='button' to="/view_sales" className='selectorNFT3'>Sales</Link> Create Sale
      <QuestionHelper
          text={TranslateString(
            300,
            'Sale creator allows the community to create and sell their own tokens in order to finance their projects. The whole process is fully decentralised.'
          )}
        />
      </h2>
      {loading ? (
        <div>
          <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
        </div>
      ) : (
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="inputColumn">

          <label htmlFor="token">
            <span><a /* rel='noreferrer' target='_blank' */ href="/#/factory_tokens">Token: (Token Creator)</a>
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Token: Click on the title (Token Creator) to create your own token. You also need to enter your token in this field.'
                )}
              />
            </span>
            <input {...register('token', { required: 'Required' })} value={tokenAddress} id="token" type="text" placeholder="0xaaE0a39AFBb7eCE9D69415DCe68B3bD0d5302Cc2" 
            onChange={(e) => handleTokenChange(e.target.value)}/>
          </label>

          <label htmlFor="softcap">
            <span>Softcap (SMR)
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Softcap (SMR): The minimum amount of funding a project must raise for the project to be considered successful.'
                )}
              />
            </span>
            <input {...register('softcap', { required: 'Required' })} type="number" placeholder="1000" 
            onChange={(e) => handleSoftCapChange(e.target.value)}
            onInput={handleInput}
            />
          </label>
          
          
          <label htmlFor="hardcap">
            <span>Hardcap (SMR)
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Hardcap (SMR): The maximum amount of funding a project can raise.'
                )}
              />
            </span>
            <input {...register('hardcap', { required: 'Required' })} type="number" placeholder="2000" 
            onChange={(e) => handleHardCapChange(e.target.value)}
            onInput={handleInput}
            />
          </label>

          <label htmlFor="tokensPerEth">
            <span>Tokens per 1 SMR Softcap
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Tokens per 1 SMR (Softcap): Number of tokens you will receive per 1 SMR if the Softcap is reached.'
                )}
              />
            </span>
            <input {...register('tokensPerEth', { required: 'Required' })} type="number" step="0.000000000001" placeholder="30" 
            onChange={(e) => handleTokensPerEthChange(e.target.value)}

            />
          </label>

          <label htmlFor="tokensPerEth2">
            <span>Tokens per 1 SMR Hardcap
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Tokens per 1 SMR (Hardcap): Number of tokens you will receive per 1 SMR if the Hardcap is reached.'
                )}
              />
            </span>
            <input {...register('tokensPerEth2', { required: 'Required' })} type="number" step="0.000000000001" placeholder="20" 
            onChange={(e) => handleTokensPerEth2Change(e.target.value)}
            />
          </label>
        </div>
        <div className="inputColumn">

          <label htmlFor="tokensPerEthListing">
            <span>Tokens per 1 SMR Listing
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Tokens per 1 SMR (Listing): Number of tokens you will receive per 1 SMR upon listing.'
                )}
              />
            </span>
            <input {...register('tokensPerEthListing', { required: 'Required' })} type="number" step="0.000000000001" placeholder="10" 
            onChange={(e) => handleTokensPerEthListingChange(e.target.value)}
            />
          </label>
          
          <label htmlFor="startTime">
            <span>Start Time
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Start Time: The time at which the project funding period begins.'
                )}
              />
            </span>
            <input {...register('startTime', { required: 'Required' })} type="datetime-local" id="startTime" placeholder="Start Time"/>
          </label>
          
          <label htmlFor="endTime">
            <span>End Time
              <QuestionHelper
                text={TranslateString(
                  300,
                  'End Time: The time at which the project funding period ends.'
                )}
              />
            </span>
            <input {...register('endTime', { required: 'Required' })} type="datetime-local" id="endTime" placeholder="End Time" />
          </label>

          <label htmlFor="liquidityPercent">
            <span>Liquidity Percent %
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Liquidity Percent %: The percentage of tokens to be added to the liquidity pool.'
                )}
              />
            </span>
            <input 
              {...register('liquidityPercent', { required: 'Required' })}
              type="number" 
              min="50"
              max="100"
              placeholder="100" 
              onChange={(e) => handleLiquidityPercentChange(e.target.value)}
              onInput={handleInput}
            />
          </label>
          
          <label htmlFor="liquidityUnlockTime">
            <span>Liquidity Unlock Days
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Liquidity Unlock Time (Days): The number of days before the liquidity in the pool can be accessed.'
                )}
              />
            </span>
            <input {...register('liquidityUnlockTime', { required: 'Required' })} type="number" placeholder="1000" 
              onInput={handleInput}
            />
          </label>
          
        </div>
        <div className="inputColumn">

          <label htmlFor="limitPerAccountEnabled">
            <span>Limit Per Account Enabled
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Limit Per Account Enabled: It changes whether there is a maximum limit on the number of tokens a single account can buy up to the softcap.'
                )}
              />
            </span>
            <input {...register('limitPerAccountEnabled', { required: 'Required' })} type="checkbox" />
          </label>

          <label htmlFor="limitPerAccount">
            <span>Limit Per Account (SMR)
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Limit Per Account (SMR): If enabled, the maximum number of tokens a single account can purchase up to the softcap.'
                )}
              />
            </span>
            <input {...register('limitPerAccount')} type="number" placeholder="100" 
              onInput={handleInput}
              />
          </label>
          


          <label htmlFor="vestingEnabled">
            <span>Vesting Enabled
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Vesting Enabled: Toggle whether there is a vesting schedule for token release.'
                )}
              />
            </span>
            <input {...register('vestingEnabled', { required: 'Required' })} type="checkbox" />
          </label>
        


          <label htmlFor="vestingInterval">
            <span>Vesting Interval (Days)
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Vesting Interval (Days): If enabled, the number of days between each token release.'
                )}
              />
            </span>
            <input {...register('vestingInterval')} type="number" placeholder="10" 
              onInput={handleInput}
            />
          </label>
          
          <label htmlFor="vestingDuration">
            <span>Vesting Duration (Days)
              <QuestionHelper
                text={TranslateString(
                  300,
                  'Vesting Duration (Days): If enabled, the total number of days over which tokens are released.'
                )}
              />
            </span>
            <input {...register('vestingDuration')} type="number" placeholder="90" 
              onInput={handleInput}
            />
          </label>
        

        </div>
        <p className='comision'>
          <span>Fee: {feeAmount}</span> 
          <img className='reward-icon2' src="/favicon2.png" alt="zero token" />
        </p>
        <p className='legend-center'>Do not add liquidity to the DEX manually before the sale is finalised, otherwise the sale will fail, be cancelled and all deposited SMR will be returned to their owners.</p>
        <button className='buttoncreatesale' type="submit">Create Sale</button>
        {error && <p className="error">{error.message}</p>}
        {tokenSupply && <p>The supply of your token is {tokenSupply} {tokenSymbol}</p>}
        {tokensToSend && <p>Will be sent {tokensToSend} {tokenSymbol} to the contract</p>}
        {percent && <p>{Number(percent).toFixed(6)}% of the supply</p>}
        {/* {saleAddress ? <p>Sale Contract: {saleAddress}</p> : null} */}
        {saleAddress ? <Link to={`/sale/${saleAddress}`} className='ahref'>Link to Sale: {saleAddress}</Link>: null}
      </form>
      )}
    </div>
  );
}

export default CreateSale;
