import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import Web3Utils from 'web3-utils';
import Web3 from 'web3'
import NFTGenesisABI from './NFTGenesisABI.json'
import './BuyNFTs.css' // Import the CSS file


// Direcciones
const NFTSale = '0xD5B44ae6A78e0D17D56022466EE47Dc3A36F77Dc'
/* marketplace 0x98242f3c338361bA958F2BfB0d3771b9fE27a6d6 */


// Web3
let web3
if (window.ethereum) {
  (window.ethereum as any).request({ method: 'eth_requestAccounts' })
  web3 = new Web3((window as any).ethereum)
} else {
  console.log('Ethereum provider not found. Make sure MetaMask is installed.')
  web3 = new Web3()
}

// Contratos
const NFTGenesisContract1 = new web3.eth.Contract(NFTGenesisABI, NFTSale)

const BuyNFT = () => {
  const [account, setAccount] = useState('')
  const [price1, setPrice1] = useState<number>(0)
  const [remaining1, setRemaining1] = useState<number>(0)
  const [loading, setLoading] = useState(true) // Agregamos el estado de carga.
  const [isEnoughBalance1, setIsEnoughBalance1] = useState<boolean>(false)
  const [balanceInWei, setBalanceInWei] = useState('0')
  const [maxSupply1, setMaxSupply1] = useState<number>(0)
  const [countdown, setCountdown] = useState<number>(0);
  const [countdownString, setCountdownString] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const targetTimestamp = 1700074800;
/*   const targetTimestamp = 1700066800; */


  useEffect(() => {
    async function getAccountAndPrices() {
      setLoading(true)
      
      const accounts = await (window.ethereum as any).request({ method: 'eth_accounts' })
      setAccount(accounts[0])

      const fetchedMaxSupply1 = await NFTGenesisContract1.methods.MAX_SUPPLY().call()
      setMaxSupply1(fetchedMaxSupply1)
      
      // Get account balance in Wei
      const balanceWei = await web3.eth.getBalance(accounts[0])
      setBalanceInWei(balanceWei)      
  
      await getPrices()
      
      const totalSupply1 = await NFTGenesisContract1.methods.totalSupply().call()
      setRemaining1(maxSupply1 - totalSupply1)
      
      setLoading(false)
    }
  
    async function getPrices() {
      setLoading(true)
      const fetchedPrice1Wei = new BigNumber(await NFTGenesisContract1.methods.NFTPrice().call())
      const ethPrice1 = web3.utils.fromWei(fetchedPrice1Wei.toFixed(), 'ether')
      setPrice1(ethPrice1)
    
      // Check if balance is enough
      setIsEnoughBalance1(new BigNumber(balanceInWei).gte(fetchedPrice1Wei))
      setLoading(false)
    }
  
    getAccountAndPrices()
    setLoading(true)
    if (window.ethereum) {
      (window.ethereum as any).on('accountsChanged', function (accounts: any[]) {
        window.location.reload();
      })
    }
    setLoading(false)
  }, [balanceInWei, maxSupply1])

  const buyNFT1 = async () => {
    try {
      setLoading(true)
      const priceWei = web3.utils.toWei(price1.toString(), 'ether')
      await NFTGenesisContract1.methods.buyNFT().send({ from: account, value: priceWei })
  
      // Update remaining NFTs after a purchase
      const totalSupply1 = await NFTGenesisContract1.methods.totalSupply().call()
      setRemaining1(maxSupply1 - totalSupply1)
      setLoading(false)
    } catch (error) {
      console.error("Error buying NFT1: ", error)
      setLoading(false)
    }
  }
  useEffect(() => {
    setLoading(true)
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const remainingSeconds = targetTimestamp - now;
  
      setCountdown(remainingSeconds);
  
      const days = Math.floor(remainingSeconds / 86400);
      const hours = Math.floor(remainingSeconds % 86400 / 3600);
      const minutes = Math.floor(remainingSeconds % 3600 / 60);
      const seconds = remainingSeconds % 60;
  
      setCountdownString(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);
      // Asegúrate de que todos los datos necesarios estén cargados antes de habilitar el botón
      if (remainingSeconds <= 0 && !loading && account && maxSupply1 > 0) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }, 1000);
    setLoading(false)
    return () => clearInterval(interval);

  }, [loading, account, maxSupply1, targetTimestamp]);

  useEffect(() => {
    setLoading(true)
    if (countdown <= 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    setLoading(false)
  }, [countdown]);
  return (
    <div className="main-container">
      <h2>Buy NFTs Tangle Bulls</h2>
      {loading || account === null ? (
        <div>
          <img className='loader' src="/images/blue-loader3.svg" alt="Loading..." />
        </div>
      ) : (
        <>
      <div className="containerpro">
        <div className="card">
          <div className="video-button-container">
            <div className="video-container">
              <img src="/tanglebulls.gif" width="100%" height="100%" alt="Tangle Bulls" />
            </div>
	{!loading && !buttonDisabled && countdown <= 0 &&
            <button 
                className={`button ${remaining1 <= 0 || !isEnoughBalance1 ? 'button-disabled' : ''}`} 
                type="button" 
                onClick={buyNFT1} 
                disabled={remaining1 <= 0 || !isEnoughBalance1}>
              Buy Tangle Bull
            </button>
	 }
  {countdown > 0 && (
    <p style={{textAlign: "center"}}>Time remaining until sales start: {countdownString}</p>
  )}
            {!isEnoughBalance1 && <p className="no-funds sold-out">Insufficient SMR</p>}
            {remaining1 <= 0 && <p className="sold-out">Sold Out</p>}
            <p>Price: {price1} SMR</p>

            <p>Remaining NFTs: {remaining1}</p>
          </div>
        </div>
      </div>
      </>
    )}
    </div>
  )
}

export default BuyNFT