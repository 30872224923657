import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Card } from '@pancakeswap-libs/uikit'

const glow = keyframes`
    0% {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.2), 0 0 9px rgba(0, 0, 0, 0.2), 0 0 13px rgba(0, 0, 0, 0.2);
    }
    50% {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2), 0 0 12px rgba(0, 0, 0, 0.2), 0 0 18px rgba(0, 0, 0, 0.2), 0 0 26px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.2), 0 0 9px rgba(0, 0, 0, 0.2), 0 0 13px rgba(0, 0, 0, 0.2);
    }
`

export const BodyWrapper = styled(Card)`
  position: relative;
  max-width: 400px;
  width: 100%;
  z-index: 5;
  overflow: auto;

  animation: ${glow} 10s infinite alternate;

  @media (min-width: 310px) {
    max-width: 400px;
    width: 310px;
  }

  @media (min-width: 320px) {
    max-width: 400px;
    width: 320px;
  }

  @media (min-width: 330px) {
    max-width: 400px;
    width: 330px;
  }

  @media (min-width: 340px) {
    max-width: 400px;
    width: 340px;
  }

  @media (min-width: 350px) {
    max-width: 400px;
    width: 350px;
  }

  @media (min-width: 400px) {
    max-width: 400px;
    width: 400px;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
